import React, {SyntheticEvent} from 'react';
import {navigate} from '@reach/router';
import {
  LOAN_APPLICATIONS_STATUSES,
  STAGES_TYPE,
} from '../../../constants/constants';

type Props = {
  clientResubmit: (e: SyntheticEvent) => void;
  stage: STAGES_TYPE;
  updateStage: (e: SyntheticEvent, stage: STAGES_TYPE) => void;
  dueDiligenceText?: string;
  dismissal?: boolean;
};

export const routeToList = (pathname: string) => {
  const regex = /\/([^\/]+)\/([^\/]+)\//;
  const matched = pathname.match(regex);
  if (matched && matched.length > 1) {
    const route = `/app/${matched[2]}/`;
    navigate(route);
  }
};

export const ActionButtons = ({
  updateStage,
  stage,
  clientResubmit,
  dueDiligenceText,
  dismissal,
}: Props) => {
  return (
    <div className="grid grid-cols-3 gap-8">
      {dismissal && (
        <button
          className="rounded-3xl bg-black px-8 py-2 text-white"
          onClick={async e => {
            await updateStage(
              e,
              LOAN_APPLICATIONS_STATUSES.SUBMITTED_FOR_DISMISSAL,
            );
          }}>
          Submit Loan For Dismissal
        </button>
      )}
      <button
        className="rounded-3xl border-blue-600 border px-8 py-2 text-blue-600"
        onClick={async e => {
          await clientResubmit(e);
        }}>
        Client Resubmit
      </button>
      <button
        className="rounded-3xl bg-blue-600 px-8 py-2 text-white"
        onClick={async e => {
          await updateStage(e, stage);
        }}>
        {dueDiligenceText ?? 'Submit for Due Diligence'}
      </button>
    </div>
  );
};
