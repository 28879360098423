import {navigate} from 'gatsby';
import React, {ReactNode, useState, useEffect} from 'react';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {showToast} from '../../components/Toast';
import {
  DEFAULT_CURRENCY,
  LOAN_APPLICATIONS_STATUSES,
} from '../../constants/constants';
import {useActiveTab} from '../../hooks/useActiveTab';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';
import {PermissionCard} from '../settings/components/PermissionCard';
import {ActionBtn} from './components/ResubmitActionBtn';

type Props = {
  path?: string;
};

export const Resubmitted = ({}: Props) => {
  const {
    loanApplications: resubmitting,
    error: resubmittingError,
    loading: resubmittingLoading,
  } = useLoanApplications([LOAN_APPLICATIONS_STATUSES.RESUBMITTING]);

  const {
    loanApplications: resubmitted,
    error: resubmittedError,
    loading: resubmittedLoading,
  } = useLoanApplications([LOAN_APPLICATIONS_STATUSES.RESUBMITTED]);

  useEffect(() => {
    if (resubmittingError) {
      console.error('loan resubmitting', resubmittingError);
      showToast({
        open: true,
        message:
          'Error occurred when loading loan active resubmission loan applications',
        positive: false,
      });
    }
    if (resubmittedError) {
      console.error('loan applications', resubmittedError);
      showToast({
        open: true,
        message: 'Error occurred when loading resubmitted loan applications',
        positive: false,
      });
    }
  }, [resubmittingError, resubmittedError]);

  const incompleteHeaders = [
    {name: 'Business Name'},
    {name: 'Client Name'},
    {name: 'Date Entered'},
    {name: 'Phone Number'},
    {name: 'Marked for submission'},
    {name: ''},
  ];

  const activeHeaders = [
    {name: 'Business Name'},
    {name: 'Client Name'},
    {name: 'Amount Applied'},
    {name: 'Date'},
    {name: 'District'},
    {name: 'Phone Number'},
    {name: 'Action'},
  ];

  const getIncompleteItems = () => {
    if (!resubmitting || !Array.isArray(resubmitting)) return [];
    return resubmitting.map(application => {
      return {
        businessName: (
          <div
            className="px-2 text-start text-blue-500 cursor-pointer"
            onClick={() => {
              navigate(`${application.id}/detail`);
            }}>
            {application.business.name}
          </div>
        ),
        clientName: application.business.borrower.name,
        date: new Date(application.createdAtUtc).toLocaleDateString(),
        phone: application.business.borrower.phoneNumber,
        resubmit: (
          <PermissionCard
            permissions={
              application?.resubmissions?.map(
                resubmission => resubmission.photoType,
              ) ?? []
            }
          />
        ),
      };
    });
  };

  const getActiveItems = () => {
    if (!resubmitted || !Array.isArray(resubmitted)) return [];
    return resubmitted.map(application => {
      return {
        businessName: (
          <div
            className="px-2 text-start text-blue-500 cursor-pointer"
            onClick={() => {
              navigate(`${application.id}/detail`);
            }}>
            {application.business.name}
          </div>
        ),
        clientName: application.business.borrower.name,
        amountApplied: formatMoney(
          application.loanQuote.principal,
          DEFAULT_CURRENCY,
        ),
        date: new Date(application.createdAtUtc).toLocaleDateString(),
        district: application.business.district,
        phone: application.business.borrower.phoneNumber,
        action: <ActionBtn id={application.id} />,
      };
    });
  };

  const [activeTab, setActiveTab] = useState('Incomplete');

  const [headers, setHeaders] = useState(incompleteHeaders);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  useEffect(() => {
    const incompleteItems = getIncompleteItems();
    const activeItems = getActiveItems();
    if (
      activeTab === 'Incomplete' &&
      items.length === 0 &&
      incompleteItems.length > 0
    ) {
      setItems(incompleteItems);
    }
    if (
      activeTab === 'Active' &&
      items.length === 0 &&
      activeItems.length > 0
    ) {
      setItems(activeItems);
    }
  }, [resubmitting, resubmitted]);

  useActiveTab(
    {setTab: setActiveTab, defaultTab: 'Incomplete', tab: activeTab},
    title => {
      setHeaders(title === 'Incomplete' ? incompleteHeaders : activeHeaders);
      setItems(
        title === 'Incomplete' ? getIncompleteItems() : getActiveItems(),
      );
      setActiveTab(title);
    },
  );

  return (
    <HomePageLayout loading={resubmittedLoading || resubmittingLoading}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Resubmitted Loans" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <div className="w-4/12">
            <Tabs
              tabs={[
                {title: 'Incomplete', active: activeTab === 'Incomplete'},
                {title: 'Active', active: activeTab === 'Active'},
              ]}
              onClick={title => {
                setHeaders(
                  title === 'Incomplete' ? incompleteHeaders : activeHeaders,
                );
                setItems(
                  title === 'Incomplete'
                    ? getIncompleteItems()
                    : getActiveItems(),
                );
                setActiveTab(title);
              }}
            />
          </div>

          <ItemsTable headers={headers} items={items} />
        </div>
      </div>
    </HomePageLayout>
  );
};
