import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {navigate} from 'gatsby';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {
  DEFAULT_CURRENCY,
  LOAN_APPLICATIONS_STATUSES,
} from '../../constants/constants';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';

type Props = {
  path?: string;
};
const pendingHeaders = [
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Date Entered DD'},
  {name: 'Amount requested'},
  {name: 'Quoted principle'},
  {name: 'Loan History'},
];

const PENDING = 'Pending';

export const DueDiligence = ({}: Props) => {
  const [activeTab, setActiveTab] = useState(PENDING);

  const [headers, setHeaders] = useState(pendingHeaders);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  const {loanApplications, error, loading} = useLoanApplications([
    LOAN_APPLICATIONS_STATUSES.DUE_DILIGENCE,
  ]);

  const getItems = () => {
    if (!loanApplications || !Array.isArray(loanApplications)) return [];
    return loanApplications.map(application => ({
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${application.id}/detail`);
          }}>
          {application.business.name}
        </div>
      ),
      clientName: application.business?.borrower?.name,
      dateEnteredDD: new Date(
        application?.stage?.createdAtUtc,
      ).toLocaleDateString(),
      amountRequested: formatMoney(
        application?.loanQuote?.amount,
        DEFAULT_CURRENCY,
      ),
      principal: formatMoney(
        application?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      loanHistory: 'n/a',
    }));
  };

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    if (items.length === 0) {
      setItems(getItems());
    }
  }, [loanApplications]);

  return (
    <HomePageLayout loading={loading}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Due Diligence" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <div className="w-4/12">
            <Tabs
              tabs={[
                {title: PENDING, active: activeTab === PENDING},
                // {title: REASSESSMENT, active: activeTab === REASSESSMENT},
              ]}
              onClick={title => {
                setHeaders(pendingHeaders);
                setItems(getItems());
                setActiveTab(title);
              }}
            />
          </div>
          <ItemsTable headers={headers} items={items} />
        </div>
      </div>
    </HomePageLayout>
  );
};
