import React from 'react';
import {NextOfKinCard} from './NextOfKinCard';
import {NextOfKinType} from '../../../hooks/useLoanApplication';

type Props = {
  contacts?: NextOfKinType[];
};

export const NextOfKin = ({contacts}: Props) => {
  return (
    <div className="p-4 border border-gray-300 col-span-3 rounded-xl w-full">
      <div className="text-blue-600 font-medium text-lg my-2">Next of Kin</div>
      <div className="grid grid-cols-1 w-full">
        {contacts?.map(contact => {
          return (
            <NextOfKinCard
              key={contact.id}
              title="Next of Kin 1"
              name={contact.name}
              relationship={contact.relationship}
              number={contact.phone}
            />
          );
        })}
      </div>
    </div>
  );
};
