import {ApolloError, useQuery} from '@apollo/client';
import {PHOTO_STATES_TYPE} from '../constants/constants';
import {LOAN_APPLICATION_QUERY} from '../queries/loanApplication';
import {LoanApplicationCategoryType} from './useUpdateLoanApplicationCategoryUpdate';

export type NextOfKinType = {
  id: string;
  name: string;
  phone: string;
  relationship: string;
};

export type PhotosType = {
  id?: string;
  url?: string;
  photoType?: string;
  status?: PHOTO_STATES_TYPE;
};

export type LoanQuoteType = {
  principal: number;
  amount: number;
  interest: number;
  interestAmount: number;
  discount: number;
  proposedAmount: number;
  termDays: string;
  pk?: string;
};

export type LocationType = {
  speed: number;
  accuracy: number;
  altitude: number;
  bearing: number;
  latitude: number;
  longitude: number;
  provider: string;
  time: string;
};

export type ProfileType = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  mobileMoneyNumber: string;
  gender: string;
  dateOfBirth: string;
  educationLevel: string;
  nationality: string;
  nin: string;
  nextOfKin: NextOfKinType[];
  photos: PhotosType[];
};

export type LenderType = {
  firstName: string;
  lastName: string;
};

export type CommentsType = {
  id: string;
  comment: string;
  staff: LenderType;
  createdAtUtc: string;
};

export type ContactAttemptsType = {
  id: string;
  channel: string;
  staff: LenderType;
  createdAtUtc: string;
  contactedSuccessfully: boolean;
};

export type VisitAttemptsType = {
  id: string;
  status: string;
  dateVisited: string;
  staff: LenderType;
  createdAtUtc: string;
};

export type TransactionsType = {
  biggestMobileMoneyReceived: number;
  smallestMobileMoneyReceived: number;
  biggestMobileMoneySent: number;
  smallestMobileMoneySent: number;
  salesLastWeek: number;
  averageSalesInADay: number;
  possibleWeeklyPayment: number;
};

export type ResubmissionType = {
  photoType: string;
  id: string;
};

export type BorrowerType = {
  firstName: string;
  lastName: string;
  nin: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  nationality: string;
  name: string;
  mobileMoneyNumber: string;
  nextOfKin: [NextOfKinType];
  nationalIdName: string;
  gender: string;
  educationLevel: string;
  preferredLanguage: string;
  photos: PhotosType[];
};

export type StageType = {
  id: string;
  createdAtUtc: string;
  loanApplicationId: string;
  endedAtUtc: string;
  stage: string;
  active: boolean;
  comment: string;
  staffId: string;
  staff: ProfileType;
  isActive: boolean;
};

export type LoanStatusType = {
  id: string;
  createdAtUtc: string;
  status: string;
  active: boolean;
  loanId: string;
  pk: number;
};

export type LoanPaymentType = {
  id: string;
  amountPaid: number;
  createdAtUtc: string;
};

export type LoanType = {
  id: string;
  loanStatus: LoanStatusType;
  loanPayments: LoanPaymentType[];
  createdAtUtc: string;
  loanTermDays: number;
  amount: number;
  pk: number;
  state: string;
  business: BusinessType;
  outStandingBalance: number;
  loanEndTimeUtc: string;
  refundAmount: number;
};

export type BusinessType = {
  id: string;
  name: string;
  type: string;
  loanType: string;
  age: string;
  photos: PhotosType[];
  district: string;
  borrower: BorrowerType;
  numberOfEmployees: number;
  location: LocationType;
  transactions: TransactionsType;
  loans: LoanType[];
  pk: string;
};

export type LoanApplicationType = {
  id: string;
  createdAtUtc: string;
  business: BusinessType;
  loanQuote: LoanQuoteType;
  comments: CommentsType[];
  stage: StageType;
  contactAttempts: ContactAttemptsType[];
  visitAttempts: VisitAttemptsType[];
  resubmissions: ResubmissionType[];
  category: LoanApplicationCategoryType;
  loan: LoanType;
  acceptedTerms: boolean;
};

type UseLoanApplicationsType = {
  loading: boolean;
  error: ApolloError | undefined;
  loanApplication: LoanApplicationType;
};

export const useLoanApplication = (id: string): UseLoanApplicationsType => {
  const {data, loading, error} = useQuery(LOAN_APPLICATION_QUERY, {
    variables: {id},
  });

  return {
    loanApplication: data?.loanApplication,
    loading,
    error,
  };
};
