import React, {ReactNode, useEffect, useState} from 'react';
import _ from 'lodash';
import {PencilAltIcon, TrashIcon} from '@heroicons/react/outline';
import {AddUserDialog} from '../../components/AddUserDialog';
import {CreateRoleDialog} from '../../components/CreateRoleDialog';
import DeletePrompt from '../../components/DeletePrompt';
import {HomePageLayout} from '../../components/HomePageLayout';
import {Header, ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {showToast} from '../../components/Toast';
import {permissions} from '../../constants/constants';
import {useAllUsers} from '../../hooks/useAllUsers';
import {useCreateRole} from '../../hooks/useCreateRole';
import {useDeleteRole} from '../../hooks/useDeleteRole';
import {useDeleteUser} from '../../hooks/useDeleteUser';
import {RolesType, useRoles} from '../../hooks/useRoles';
import {useSaveUser} from '../../hooks/useSaveUser';
import {PermissionCard} from './components/PermissionCard';
import {useActiveTab} from '../../hooks/useActiveTab';
import {useHasPermission} from '../../hooks/useHasPermission';
import AccessDenied from '../../components/AccessDenied';
import {LenderObject} from '../../gql/graphql';

type Props = {
  path?: string;
};

type TableTitleProps = {
  buttonTitle?: string;
  onClick?: () => void;
};

const usersHeaders = [
  {name: 'First Name'},
  {name: 'Last Name'},
  {name: 'Email Address'},
  {name: 'Phone Number'},
  {name: 'Roles'},
  {name: 'Edit'},
  {name: 'Delete'},
];

const rolesHeaders = [
  {name: 'Role Name'},
  {name: 'Permissions'},
  {name: 'Date Created'},
  {name: 'Edit'},
  {name: 'Delete'},
];

const permissionsHeaders = [
  {name: 'Permission Name'},
  {
    name: (
      <div className="w-full flex justify-start items-center">
        <span className="text-right px-2">Description</span>
      </div>
    ),
  },
];

type PermissionDescriptionProps = {
  description: string;
};

const PermissionDescription = ({description}: PermissionDescriptionProps) => {
  return (
    <div className="w-full flex justify-start items-center">
      <span className="text-right">{description}</span>
    </div>
  );
};

const permissionsItems = Object.values(permissions).map(permission => {
  return {
    permission: permission.name.split('_').join(' '),
    description: <PermissionDescription description={permission.description} />,
  };
});

export const SettingsTableTitle = ({buttonTitle, onClick}: TableTitleProps) => {
  return (
    <div className="flex flex row justify-between gap-4 justify-center mt-4">
      <Search left containerClass={''} />
      {buttonTitle && (
        <button
          className="mx-8 bg-blue-600 text-gray-200 rounded-md px-16"
          onClick={() => onClick && onClick()}>
          {buttonTitle}
        </button>
      )}
    </div>
  );
};

const USERS = 'users';
const ROLES = 'roles';
const PERMISSIONS = 'permissions';

export const Settings = ({}: Props) => {
  const {roles, error: rolesError, loading: rolesLoading} = useRoles();
  const {users, error: usersError, loading: usersLoading} = useAllUsers();
  const [savingUser, setSavingUser] = useState<boolean>(false);
  const [savingRole, setSavingRole] = useState<boolean>(false);
  const [deleteRolePrompt, setDeleteRolePrompt] = useState<{
    open: boolean;
    message: string;
    id: string;
  }>({open: false, message: '', id: ''});
  const [deleteUserPrompt, setDeleteUserPrompt] = useState<{
    open: boolean;
    message: string;
    id: string;
  }>({open: false, message: '', id: ''});
  const {
    deleteRole,
    success: deleteRoleSuccess,
    loading: deleteRoleLoading,
    error: deleteRoleError,
  } = useDeleteRole();

  const {
    deleteUser,
    success: deleteUserSuccess,
    loading: deleteUserLoading,
    error: deleteUserError,
  } = useDeleteUser();
  const {
    saveUser,
    loading: saveUserMutationRunning,
    data: savedUser,
    error: saveUserError,
  } = useSaveUser();

  const {
    createRole,
    loading: savingRoleMutation,
    error: saveRoleError,
    role: savedRole,
  } = useCreateRole();

  const [remoteItems, setRemoteItems] = useState<{
    users: {[x: string]: string | ReactNode}[];
    roles: {
      name?: string;
      [x: string]: string | ReactNode;
    }[];
  }>({users: [], roles: []});

  const getRolesItems = (
    roles?: RolesType[],
  ): {[x: string]: string | ReactNode}[] => {
    if (!roles || !Array.isArray(roles)) return [];
    return roles.map(role => {
      return {
        role: role.name,
        permissions: (
          <PermissionCard
            key={`permission_${role.name}`}
            permissions={role.permissions}
          />
        ),
        dateCreated: new Date(role.createdAtUtc).toLocaleDateString(),
        update: (
          <div
            className="w-full flex items-center cursor-pointer"
            onClick={() => {
              setAddRoleOpen({open: true, role});
            }}>
            <PencilAltIcon
              key={`edit_${role.name}`}
              className="h-6 w-6 hover:text-blue-600"
            />
          </div>
        ),
        delete: (
          <div className="w-full flex items-center cursor-pointer">
            <TrashIcon
              key={`delete_${role.name}`}
              className="h-6 w-6 hover:text-blue-600"
              onClick={() =>
                setDeleteRolePrompt({
                  ...deleteRolePrompt,
                  open: true,
                  id: role.name,
                  message: `Are you sure you want to delete the '${role.name}' role`,
                })
              }
            />
          </div>
        ),
      };
    });
  };

  const getUsersItems = (
    users?: LenderObject[],
  ): {[x: string]: string | ReactNode}[] => {
    if (!users || !Array.isArray(users)) return [];
    return users.map(user => {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phoneNumber,
        roles: (
          <div className="flex flex-row items-center">
            <PermissionCard permissions={user.roles} />
          </div>
        ),
        update: (
          <div
            className="w-full flex items-center cursor-pointer"
            onClick={() => {
              setAddUserOpen({user: user, open: true});
            }}>
            <PencilAltIcon className="h-6 w-6 hover:text-blue-600" />
          </div>
        ),
        delete: (
          <div className="w-full flex items-center cursor-pointer">
            <TrashIcon
              className="h-6 w-6 hover:text-blue-600"
              onClick={() => {
                console.log('clicking delete icon');
                setDeleteUserPrompt({
                  ...deleteUserPrompt,
                  open: true,
                  id: user.id,
                  message: `Are you sure you want to delete '${user.firstName} ${user.lastName}'`,
                });
              }}
            />
          </div>
        ),
      };
    });
  };

  useEffect(() => {
    if (roles?.length !== 0 && remoteItems.roles.length !== roles?.length) {
      const remoteRoles = getRolesItems(roles);
      setRemoteItems({...remoteItems, roles: remoteRoles});
      if (activeTab === ROLES) setItems(remoteRoles);
    }
  }, [roles]);

  useEffect(() => {
    if (users?.length !== 0 && remoteItems.users.length !== users?.length) {
      const remoteUsers = getUsersItems(users);
      setRemoteItems({...remoteItems, users: remoteUsers});
      if (activeTab === USERS) setItems(remoteUsers);
    }
  }, [users]);

  const [showAddRole, setAddRoleOpen] = useState<{
    open: boolean;
    role?: RolesType;
  }>({open: false});
  const [showAddUser, setAddUserOpen] = useState<{
    open: boolean;
    user?: LenderObject;
  }>({open: false});

  useEffect(() => {
    if (rolesError) {
      console.error('roles error', rolesError);
      showToast({
        open: true,
        message: 'Error occurred when loading roles',
        positive: false,
      });
    }
    if (usersError) {
      console.error('roles error', usersError);
      showToast({
        open: true,
        message: 'Error occurred when loading users',
        positive: false,
      });
    }
    if (saveUserError) {
      console.error('save user error', saveUserError);
      setSavingUser(false);
      showToast({
        open: true,
        message: 'Error occurred when saving user',
        positive: false,
      });
    }
    if (saveRoleError) {
      console.error('save role error', saveRoleError);
      setSavingRole(false);
      showToast({
        open: true,
        message: 'Error occurred when saving role',
        positive: false,
      });
    }
    if (deleteUserError) {
      console.error('delete user error', deleteUserError);
      setDeleteUserPrompt({...deleteUserPrompt, open: false});
      showToast({
        open: true,
        message: 'Error occurred when deleting user',
        positive: false,
      });
    }
    if (deleteRoleError) {
      console.error('delete role error', deleteUserError);
      setDeleteRolePrompt({...deleteRolePrompt, open: false});
      showToast({
        open: true,
        message: 'Error occurred when deleting role',
        positive: false,
      });
    }
  }, [
    rolesError,
    usersError,
    saveUserError,
    saveRoleError,
    deleteUserError,
    deleteRoleError,
  ]);

  useEffect(() => {
    console.log('delete user', deleteUserSuccess);
    if (savedUser) {
      setSavingUser(false);
      setAddUserOpen({...showAddUser, open: false});
      // update users
      console.log('user saved successfully');
      showToast({
        open: true,
        message: 'User saved successfully',
        positive: true,
      });
    }
    if (savedRole) {
      setSavingRole(false);
      setAddRoleOpen({...showAddRole, open: false});
      // update users
      console.log('role saved successfully');
      showToast({
        open: true,
        message: 'Role saved successfully',
        positive: true,
      });
    }
    if (deleteRoleSuccess) {
      setDeleteRolePrompt({...deleteRolePrompt, open: false});
      console.log('delete role successful');
      showToast({
        open: true,
        message: 'Role deleted successfully',
        positive: true,
      });
    }
    if (deleteRoleSuccess === false) {
      setDeleteRolePrompt({...deleteRolePrompt, open: false});
      console.log('delete role failed');
      showToast({
        open: true,
        message: 'Delete Role failed',
        positive: false,
      });
    }
    if (deleteUserSuccess) {
      setDeleteRolePrompt({...deleteUserPrompt, open: false});
      console.log('delete user successful');
      showToast({
        open: true,
        message: 'User deleted successfully',
        positive: true,
      });
    }
    if (deleteUserSuccess === false) {
      console.log('delete user', deleteUserSuccess);
      setDeleteRolePrompt({...deleteUserPrompt, open: false});
      console.log('delete user failed');
      showToast({
        open: true,
        message: 'Delete user failed',
        positive: false,
      });
    }
  }, [savedUser, savedRole, deleteRoleSuccess, deleteUserSuccess]);

  //set active tab
  const [activeTab, setActiveTab] = useState('');
  const [headers, setHeaders] = useState<Header[]>([]);
  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  useActiveTab(
    {setTab: setActiveTab, defaultTab: USERS, tab: activeTab},
    tab => {
      switch (tab) {
        case USERS:
          setHeaders(usersHeaders);
          setItems(remoteItems.users);
          break;
        case ROLES:
          setHeaders(rolesHeaders);
          setItems(remoteItems.roles);
          break;
        case PERMISSIONS:
          setHeaders(permissionsHeaders);
          setItems(permissionsItems);
          break;
      }
    },
  );
  const hasPerm = useHasPermission();

  if (!hasPerm('/app/settings')) return <AccessDenied />;
  return (
    <HomePageLayout
      loading={
        rolesLoading ||
        usersLoading ||
        savingUser ||
        saveUserMutationRunning ||
        savingRole ||
        savingRoleMutation ||
        deleteRoleLoading ||
        deleteUserLoading
      }>
      {showAddRole.open && (
        <CreateRoleDialog
          createRole={createRole}
          open={showAddRole.open}
          setLoading={setSavingRole}
          role={showAddRole.role}
          onClose={() => setAddRoleOpen({...showAddRole, open: false})}
        />
      )}
      {showAddUser.open && (
        <AddUserDialog
          open={showAddUser.open}
          onClose={() => setAddUserOpen({...showAddUser, open: false})}
          saveUser={saveUser}
          setLoading={setSavingUser}
          roles={
            roles && Array.isArray(roles)
              ? roles?.map(role => role.name)
              : undefined
          }
          user={showAddUser.user}
        />
      )}
      {deleteRolePrompt.open && (
        <DeletePrompt
          open={deleteRolePrompt.open}
          title="Are you sure?"
          message={deleteRolePrompt.message}
          onClose={() =>
            setDeleteRolePrompt({...deleteRolePrompt, open: false})
          }
          btnText="Ok"
          id={deleteRolePrompt.id}
          onClick={role => deleteRole({variables: {role}})}
        />
      )}
      {deleteUserPrompt.open && (
        <DeletePrompt
          open={deleteUserPrompt.open}
          title="Are you sure?"
          message={deleteUserPrompt.message}
          onClose={() =>
            setDeleteUserPrompt({...deleteUserPrompt, open: false})
          }
          btnText="Ok"
          id={deleteUserPrompt.id}
          onClick={id => deleteUser({variables: {id}})}
        />
      )}
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Settings" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          {(() => {
            switch (activeTab) {
              case USERS:
                return (
                  <SettingsTableTitle
                    buttonTitle="Add Users"
                    onClick={() => setAddUserOpen({open: true})}
                  />
                );
              case ROLES:
                return (
                  <SettingsTableTitle
                    buttonTitle="Add Roles"
                    onClick={() => {
                      setAddRoleOpen({open: true});
                    }}
                  />
                );
              case PERMISSIONS:
                return <SettingsTableTitle />;
            }
          })()}
          <div className="w-5/12">
            <Tabs
              tabs={[
                {title: USERS, active: activeTab === USERS},
                {title: ROLES, active: activeTab === ROLES},
                {title: PERMISSIONS, active: activeTab === PERMISSIONS},
              ]}
              onClick={title => {
                switch (title) {
                  case USERS:
                    setHeaders(usersHeaders);
                    setItems(remoteItems.users);
                    break;
                  case ROLES:
                    setHeaders(rolesHeaders);
                    setItems(remoteItems.roles);
                    break;
                  case PERMISSIONS:
                    setHeaders(permissionsHeaders);
                    setItems(permissionsItems);
                    break;
                }
                setActiveTab(title);
              }}
            />
          </div>
          <ItemsTable items={items} headers={headers} />
        </div>
      </div>
    </HomePageLayout>
  );
};
