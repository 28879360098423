import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {
  CameraIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';
import {showToast} from '../../../components/Toast';
import {PHOTO_STATES, PHOTO_STATES_TYPE} from '../../../constants/constants';
import {PhotosType} from '../../../hooks/useLoanApplication';
import {useUpdateBusinessPhoto} from '../../../hooks/useUpdateBusinessPhoto';
import {snakeToSentenceCase} from '../../../utils/snake-to-sentence-case';
import {PhotoState} from './PhotoState';
import {useUpdateBorrowerPhotos} from '../../../hooks/useUpdateBorrowerPhoto';

type LocalPhotoType = {
  status?: PHOTO_STATES_TYPE;
  url?: string;
  id?: string;
  photoType?: string;
  comment?: string;
};

type Props = {
  photos?: PhotosType[];
  setLoading: (loading: boolean) => void;
  setExpandedPhoto: ({
    open,
    photo,
  }: {
    open: boolean;
    photo?: PhotosType;
  }) => void;
  photoStatuses: {
    [x: string]: {status: PHOTO_STATES_TYPE; photoType?: string} | undefined;
  };
  title?: string;
  photoGroup?: 'BORROWER' | 'BUSINESS';
};

export const Photos = ({
  photos,
  setLoading,
  setExpandedPhoto,
  title,
  photoGroup,
}: Props) => {
  const {
    updateBusinessPhotos,
    loading,
    photos: businessPhotos,
  } = useUpdateBusinessPhoto();

  const {
    updateBorrowerPhotos,
    loading: loadingBorrowerPhotos,
    photos: borrowerPhotos,
  } = useUpdateBorrowerPhotos();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [localPhotos, setPhotos] = useState<{[x: string]: any}>({});
  const [update, setUpdate] = useState<boolean>(false);

  const getPhotos = (): {[x: string]: LocalPhotoType} => {
    const _photos = {} as any;
    photos?.forEach(photo => {
      if (photo?.id)
        _photos[photo?.id] = {
          ...photo,
          photoType: snakeToSentenceCase(photo.photoType),
        };
    });
    return _photos;
  };

  useEffect(() => {
    const statePhotos = getPhotos();
    setPhotos(statePhotos);
  }, [photos]);

  useEffect(() => {
    if (businessPhotos) {
      showToast({
        open: true,
        message: `Business Photos updated successfully`,
        positive: true,
      });
    }
  }, [businessPhotos]);

  useEffect(() => {
    if (borrowerPhotos) {
      showToast({
        open: true,
        message: `User Photos updated successfully`,
        positive: true,
      });
    }
  }, [borrowerPhotos]);

  useEffect(() => {
    setLoading(loading || loadingBorrowerPhotos);
  }, [loading, loadingBorrowerPhotos]);

  const handleChange = async (value: string, key: string, id?: string) => {
    if (!id) return;
    setPhotos({
      ...localPhotos,
      [id]: {...localPhotos[id], [key]: value},
    });
    setUpdate(true);
  };

  const savePhotos = async () => {
    try {
      console.log('saving new photos');

      const _photos = Object.values(localPhotos).map(photo => ({
        id: photo.id,
        photo: _.omit(photo, ['id', '__typename', 'photoType']),
      }));
      switch (photoGroup) {
        case 'BORROWER':
          await updateBorrowerPhotos({variables: {photos: _photos}});
          break;
        case 'BUSINESS':
          await updateBusinessPhotos({variables: {photos: _photos}});
          break;
        default:
          throw 'Unknown photo group';
      }
      setUpdate(false);
      showToast({
        open: true,
        message: 'Photos updated.',
        positive: true,
      });
    } catch (e: any) {
      console.error('error occurred when updating photo', e);
      showToast({
        open: true,
        message: `Error occurred when updating photo: ${e.message}`,
        positive: false,
      });
    }
  };
  return (
    <>
      <div className="flex flex-col  w-full p-4">
        <div className="flex flex-row items-center">
          <div className="text-blue-600 font-medium text-lg my-2 w-2/12">
            {title ?? 'Photos'}
          </div>
          <div className="relative overflow-hidden px-4">
            <div className="h-6 w-6" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <ChevronDownIcon className="h-full w-full text-gray-400" />
              ) : (
                <ChevronUpIcon className="h-full w-full text-gray-400" />
              )}
            </div>
          </div>
        </div>
        <div className={`${isOpen ? '' : 'hidden'}`}>
          {Object.values(localPhotos)?.map((photo: LocalPhotoType) => {
            return (
              <div
                className="flex flex-row w-full items-center mt-6"
                key={photo.id}>
                {photo?.url ? (
                  <div
                    className="h-44 w-44 mx-8 flex items-center justify-center bg-gray-50 m-2 rounded-lg cursor-pointer"
                    onClick={() => {
                      setExpandedPhoto({open: true, photo});
                    }}>
                    <img src={photo.url} className="w-full h-full "></img>
                  </div>
                ) : (
                  <div className="h-44 w-44 mx-8 flex items-center justify-center bg-gray-50 rounded-lg cursor-pointer">
                    <CameraIcon className="text-purple-600 h-12 w-12" />
                  </div>
                )}
                <div className="flex flex-col grow">
                  <div className="py-2">{photo.photoType}</div>
                  {!photo?.url && (
                    <p className="text-red-500 italic">
                      This photo was not submitted
                    </p>
                  )}
                  <div className="grid grid-cols-3 gap-4 mt-4">
                    <PhotoState
                      label="Unknown"
                      value={PHOTO_STATES.UNKNOWN}
                      state={
                        photo?.id
                          ? localPhotos[photo?.id]?.status ===
                            PHOTO_STATES.UNKNOWN
                          : false
                      }
                      setActiveState={async status => {
                        showToast({
                          open: true,
                          message: "Can't change photo status to unknown",
                          positive: false,
                        });
                      }}
                    />
                    <PhotoState
                      label="Good"
                      value={PHOTO_STATES.GOOD}
                      state={
                        photo?.id
                          ? localPhotos[photo?.id]?.status === PHOTO_STATES.GOOD
                          : false
                      }
                      setActiveState={async status => {
                        photo?.id &&
                          (await handleChange(status, 'status', photo.id));
                      }}
                    />
                    <PhotoState
                      label="Bad"
                      value={PHOTO_STATES.BAD}
                      state={
                        photo?.id
                          ? localPhotos[photo?.id]?.status === PHOTO_STATES.BAD
                          : false
                      }
                      setActiveState={async status => {
                        photo?.id &&
                          (await handleChange(status, 'status', photo.id));
                      }}
                    />
                  </div>
                  <form className="flex flex-col w-full">
                    <label
                      htmlFor={`photo-comment-${photo.id}`}
                      className="text-gray-600 font-light mx-2 my-2">
                      Photo Comment
                    </label>
                    <div className="flex flex-row items-center">
                      <input
                        id={`photo-comment-${photo.id}`}
                        type="text"
                        value={photo?.comment ?? ''}
                        className="border border-gray-300 rounded-md px-4 py-2 w-full"
                        onChange={
                          async e => {
                            await handleChange(
                              e.target.value,
                              'comment',
                              photo.id,
                            );
                          }
                          // setProposedAmount({amount: e.target.value})
                        }
                      />
                    </div>
                  </form>
                </div>
              </div>
            );
          })}
          <div className="w-full p-4 flex justify-end">
            <button
              disabled={!update}
              className={`rounded-3xl px-8 py-2 text-white ${
                update ? 'bg-green-600' : 'bg-gray-300'
              }`}
              onClick={async () => await savePhotos()}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

Photos.defaultProps = {
  photoGroup: 'BUSINESS',
};
