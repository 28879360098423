import React, {ReactNode, SyntheticEvent} from 'react';
import {XIcon} from '@heroicons/react/outline';

type Props = {
  title: string;
  description: string;
  onClose: () => void;
  children: ReactNode;
  open: boolean;
  btnText: string;
  onSubmit: (e: SyntheticEvent) => void;
};

export const Dialog = ({
  open,
  onClose,
  children,
  title,
  description,
  btnText,
  onSubmit,
}: Props) => {
  return (
    <>
      {open && (
        <div
          className="-ml-64 -mt-16 fixed w-screen h-screen h-full
            bg-gray-100 flex items-center justify-center z-30"
          style={{background: 'rgba(0,0,0, 0.6)'}}>
          <div className="w-5/12 bg-white rounded-md pb-8">
            <div className="flex flex-row items-center justify-center p-4">
              <div className="grow">
                <div className="font-medium py-2 px-2">{title}</div>
                <div className="text-gray-500 px-2">{description}</div>
              </div>
              <button className="p-2" onClick={() => onClose()}>
                <XIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="w-full border-b border-gray-200 shadow-sm" />
            <form className="flex flex-col w-ful px-8 mt-4" onSubmit={onSubmit}>
              {children}
              <div className="flex flex-row items-center justify-end mt-4">
                <button
                  className="px-4 py-2 text-gray-6000"
                  onClick={() => onClose()}>
                  cancel
                </button>
                <input
                  type="submit"
                  value={btnText}
                  className="px-6 py-2 bg-blue-600 text-gray-200 mx-2 rounded-md cursor-pointer"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
