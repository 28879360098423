import {MutationFunctionOptions} from '@apollo/client';
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {LenderObject} from '../gql/graphql';
import {Checkbox} from './checkbox';
import {Dialog} from './Dialog';
import {SingleInput} from './SingleInput';

type Props = {
  open: boolean;
  onClose: () => void;
  saveUser: (options: MutationFunctionOptions) => void;
  setLoading: (loading: boolean) => void;
  roles?: string[];
  user?: LenderObject;
};

type FormDataType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  password: string;
  confirmPassword?: string;
  [x: string]: any;
};
type FormDataErrorType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  password?: string;
  confirmPassword?: string;
};

const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  dateOfBirth: new Date().toDateString(),
  password: '',
  confirmPassword: '',
};
export const AddUserDialog = ({
  open,
  onClose,
  saveUser,
  setLoading,
  roles,
  user,
}: Props) => {
  const [formData, setFormData] = useState<FormDataType>(initialFormData);

  useEffect(() => {
    if (user) {
      const roles = user.roles?.reduce((prev, current) => {
        prev[`role_${current}`] = true;
        return prev;
      }, {} as any);
      setFormData({
        ...formData,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        dateOfBirth: user.dateOfBirth,
        ...roles,
      });
    } else {
      setFormData(initialFormData);
    }
  }, [user]);

  const [formDataErrors, setFormDataErrors] = useState<FormDataErrorType>({});

  const onChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    setFormData({...formData, [field]: e.target.value});
    setFormDataErrors({...formDataErrors, [field]: undefined});
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    console.log('submitting form', formData);
    setLoading(true);
    let invalidForm = false;
    //validation
    if (formData.firstName.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        firstName: 'Please enter the user first name',
      });
      invalidForm = true;
    }
    if (formData.lastName.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        lastName: 'Please enter the user last name',
      });
      invalidForm = true;
    }
    if (formData.dateOfBirth.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        dateOfBirth: 'Please select a date of birth',
      });
      invalidForm = true;
    }
    if (!/\S+@\S+\.\S+/g.test(formData.email)) {
      setFormDataErrors({
        ...formDataErrors,
        email: 'Email address incorrect',
      });
      invalidForm = true;
    }
    if (!/^(?:2560|\+256|256|0|)([237]\d{8})$/g.test(formData.phoneNumber)) {
      setFormDataErrors({
        ...formDataErrors,
        phoneNumber: 'Phone number is incorrect',
      });
      invalidForm = true;
    }
    if (formData.password.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        password: 'Please enter user password',
      });
      invalidForm = true;
    }
    if (formData?.confirmPassword?.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        confirmPassword: 'Please confirm user password',
      });
      invalidForm = true;
    }

    if (formData.password !== formData.confirmPassword) {
      setFormDataErrors({
        ...formDataErrors,
        confirmPassword: 'Passwords do not match',
      });
      invalidForm = true;
    }
    if (invalidForm) {
      console.log('invalid form', formDataErrors);
      setLoading(false);
      return;
    }
    const correctFormData = {...formData};
    delete correctFormData.confirmPassword;
    const keys = Object.keys(correctFormData);
    const newRoles = keys.reduce((prev, current_value) => {
      if (current_value.startsWith('role')) {
        const role_name = current_value.slice(5);
        if (correctFormData[current_value]) {
          prev.push({name: role_name});
        }
      }
      return prev;
    }, [] as any);

    correctFormData.roles = newRoles;
    saveUser({variables: correctFormData});

    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Add New User"
      btnText="Save User"
      description="Fill in the form below to add a new user"
      onSubmit={onSubmit}>
      <div>
        <SingleInput
          title="First Name"
          value={formData.firstName}
          error={formDataErrors.firstName}
          onChange={e => onChange(e, 'firstName')}
        />
        <SingleInput
          title="Last Name"
          value={formData.lastName}
          error={formDataErrors.lastName}
          onChange={e => onChange(e, 'lastName')}
        />
        <SingleInput
          title="Email Address"
          type="email"
          value={formData.email}
          error={formDataErrors.email}
          onChange={e => onChange(e, 'email')}
        />
        <SingleInput
          title="Phone Number"
          type="tel"
          value={formData.phoneNumber}
          error={formDataErrors.phoneNumber}
          onChange={e => onChange(e, 'phoneNumber')}
        />
        <SingleInput
          title="Date of Birth"
          type="date"
          value={formData.dateOfBirth}
          error={formDataErrors.dateOfBirth}
          onChange={e => onChange(e, 'dateOfBirth')}
        />
        <SingleInput
          title="Password"
          type="password"
          value={formData.password}
          error={formDataErrors.password}
          onChange={e => onChange(e, 'password')}
        />
        <SingleInput
          title="Confirm Password"
          type="password"
          value={formData.confirmPassword}
          error={formDataErrors.confirmPassword}
          onChange={e => onChange(e, 'confirmPassword')}
        />
        {roles && (
          <>
            <span className="mt-8 text-gray-600">Select User Roles</span>
            <div className="grid grid-cols-3 gap-4 w-full mt-4">
              {roles?.map(role => (
                <Checkbox
                  title={role}
                  checked={formData[`role_${role}`] ?? false}
                  setChecked={checked =>
                    setFormData({...formData, [`role_${role}`]: checked})
                  }
                  key={role?.toString()}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
