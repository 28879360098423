export const snakeToSentenceCase = (text?: string): string | undefined => {
  if (!text) return text;
  return text
    .split('_')
    .map(word => toTitleCase(word))
    .join(' ');
};

export const toTitleCase = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};
