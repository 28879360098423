import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {Dialog} from '../../../components/Dialog';
import {showToast} from '../../../components/Toast';
import {useCreateComment} from '../../../hooks/useCreateComment';

type Props = {
  setLoading: (loading: boolean) => void;
  loanApplicationId?: string;
  open: boolean;
  onClose: () => void;
};

export const AddCommentDialog = ({
  setLoading,
  onClose,
  open,
  loanApplicationId,
}: Props) => {
  const [comment, setComment] = useState<string>('');
  const [inputError, setInputError] = useState<string | undefined>();

  const {comment: createdComment, createComment, loading} = useCreateComment();

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
    if (inputError) setInputError(undefined);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    let valid = true;
    try {
      //validate data

      if (!comment || comment === '') {
        valid = false;
      }
      if (valid) {
        //submit data
        console.log('submitting correct data', comment);
        await createComment({
          variables: {
            comment: {comment, loanApplicationId},
          },
        });
        showToast({
          open: true,
          message: 'Comment added successfully',
          positive: true,
        });
      }
    } catch (e: any) {
      showToast({
        open: true,
        message: `An Error occurred when saving comment: ${e.message}`,
        positive: false,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={'Add Comment'}
      btnText="Submit"
      description="Add new Comment to the loan application"
      onSubmit={async e => await onSubmit(e)}>
      <>
        <label htmlFor="comment-input">Comment</label>
        <textarea
          id="comment-input"
          rows={3}
          onChange={handleChange}
          className="border border-gray-300 rounded-md px-4 py-2 mt-4"
        />
        {inputError && (
          <span className="py-2 px-4 text-red-600">{inputError}</span>
        )}
      </>
    </Dialog>
  );
};
