import React, {SyntheticEvent, useEffect, useState} from 'react';
import {Dialog} from '../../Dialog';
import {invalidInput, validInput} from '../../Toast';
import {PhotoState} from './PhotoState';
import {VISIT_ATTEMPTS_OPTIONS} from '../../../constants/constants';
import {useCreateVisitAttempt} from '../../../hooks/useCreateVisitAttempts';

type Props = {
  setLoading: (loading: boolean) => void;
  loanApplicationId?: string;
  open: boolean;
  onClose: () => void;
};

export const AddVisitAttemptDialog = ({
  setLoading,
  onClose,
  open,
  loanApplicationId,
}: Props) => {
  const [visitAttempt, setVisitAttempt] = useState<string>(
    VISIT_ATTEMPTS_OPTIONS.UNKNOWN,
  );

  const [inputError, setInputError] = useState<string | undefined>();

  const {loading, createVisitAttempt} = useCreateVisitAttempt();

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const handleChange = (value: string) => {
    setVisitAttempt(value);
    if (inputError) setInputError(undefined);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    let valid = true;
    try {
      //validate data
      if (
        visitAttempt === '' ||
        visitAttempt === VISIT_ATTEMPTS_OPTIONS.UNKNOWN
      ) {
        invalidInput(
          "Please select either 'Confirmed' or 'Not Confirmed Before Save",
        );
        setInputError(
          "Please select either 'Confirmed' or 'Not Confirmed Before Save",
        );
      }
      if (valid) {
        //submit data
        console.log('submitting correct data', visitAttempt);
        await createVisitAttempt({
          variables: {
            visitAttempt: {
              status: visitAttempt,
              loanApplicationId,
            },
          },
        });
        validInput('Contact Attempt added successfully');
      }
    } catch (e: any) {
      console.error('An error occurred when doing mutation', e);
      invalidInput(
        `An Error occurred when saving contact Attempt: ${e.message}`,
      );
    } finally {
      setLoading(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={'Add Visit Attempt'}
      btnText="Submit"
      description="Add new Visit Attempt"
      onSubmit={async e => await onSubmit(e)}>
      <div className="grid grid-cols-3 gap-3 w-full">
        <PhotoState
          label="Unknown"
          value={VISIT_ATTEMPTS_OPTIONS.UNKNOWN}
          state={visitAttempt === VISIT_ATTEMPTS_OPTIONS.UNKNOWN}
          setActiveState={async status =>
            handleChange(VISIT_ATTEMPTS_OPTIONS.UNKNOWN)
          }
        />
        <PhotoState
          label="Confirmed"
          value={VISIT_ATTEMPTS_OPTIONS.CONFIRMED}
          state={visitAttempt === VISIT_ATTEMPTS_OPTIONS.CONFIRMED}
          setActiveState={async status =>
            handleChange(VISIT_ATTEMPTS_OPTIONS.CONFIRMED)
          }
        />
        <PhotoState
          label="Not Confirmed"
          value={VISIT_ATTEMPTS_OPTIONS.NOT_CONFIRMED}
          state={visitAttempt === VISIT_ATTEMPTS_OPTIONS.NOT_CONFIRMED}
          setActiveState={async status =>
            handleChange(VISIT_ATTEMPTS_OPTIONS.NOT_CONFIRMED)
          }
        />
      </div>
    </Dialog>
  );
};
