import React from 'react';

type TransactionsTicketType = {
  title: string;
  description: string | number | null;
};

export const TransactionsTicket = ({
  title,
  description,
}: TransactionsTicketType) => {
  return (
    <div className="flex flex-col mt-5">
      <div className="text-base text-gray-500">{title}</div>
      <div className="text-lg text-black pt-1 font-medium">{description}</div>
    </div>
  );
};
