import {gql} from '@apollo/client';
import {LOAN_FIELDS} from '../mutations/disburseLoan';
import {BUSINESS_FIELDS} from './loanApplication';

export const ALL_LOANS = gql`
  ${LOAN_FIELDS}
  ${BUSINESS_FIELDS}
  query AllLoans($statuses: [LoanStatesType], $sort: [LoanObjectSortEnum]) {
    loans(statuses: $statuses, sort: $sort) {
      edges {
        node {
          ...LoanFields
          business {
            ...BusinessFields
          }
        }
      }
    }
  }
`;
