import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {Dialog} from '../../../components/Dialog';
import {invalidInput, validInput} from '../../../components/Toast';
import {useDisburseLoan} from '../../../hooks/useDisburseLoan';

type Props = {
  setLoading: (loading: boolean) => void;
  loanApplicationId?: string;
  open: boolean;
  onClose: () => void;
};

export const DisburseLoanDialog = ({
  setLoading,
  onClose,
  open,
  loanApplicationId,
}: Props) => {
  const [otp, setOtp] = useState<string>('');
  const [inputError, setInputError] = useState<string | undefined>();

  const {disburseLoan, loading, loan} = useDisburseLoan();

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    if (inputError) setInputError(undefined);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      //validate data
      if (otp === '') {
        setInputError('Please enter otp');
        return;
      }
      //submit data
      console.log('submitting correct data', otp, loanApplicationId);
      await disburseLoan({variables: {code: otp, loanApplicationId}});

      validInput('Loan Disbursed Successfully');
    } catch (e: any) {
      invalidInput(`An Error occurred when disbursing loan: ${e.message}`);
    } finally {
      setLoading(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={'Disburse Loan'}
      btnText="Disburse"
      description="Enter Otp sent to your registered number"
      onSubmit={async e => await onSubmit(e)}>
      <>
        <label htmlFor="disburse-loan-opt-input">OTP</label>
        <input
          id="disburse-loan-opt-input"
          type="text"
          onChange={handleChange}
          className="border border-gray-300 rounded-md px-4 py-2 my-4"
        />
        {inputError && (
          <span className="py-2 px-4 text-red-600">{inputError}</span>
        )}
      </>
    </Dialog>
  );
};
