import {ApolloError, useQuery} from '@apollo/client';
import {STAGES_TYPE} from '../constants/constants';
import {LOAN_APPLICATIONS} from '../queries/loanApplications';
import {useEdges} from './useEdges';
import {LoanApplicationType} from './useLoanApplication';

type UseLoanApplicationsType = {
  loading: boolean;
  error: ApolloError | undefined;
  loanApplications: LoanApplicationType[] | undefined;
};

type LOAN_APPLICATIONS_SORT_TYPE =
  | 'ID_ASC'
  | 'ID_DESC'
  | 'CREATED_AT_UTC_ASC'
  | 'CREATED_AT_UTC_DESC'
  | 'BUSINESS_ID_ASC'
  | 'BUSINESS_ID_DESC'
  | 'CURRENCY_ASC'
  | 'CURRENCY_DESC'
  | 'LOAN_PURPOSE_ASC'
  | 'LOAN_PURPOSE_DESC'
  | 'ACCEPTED_TERMS_ASC'
  | 'ACCEPTED_TERMS_DESC'
  | 'AGREED_TO_LOAN_AGREEMENT_ASC'
  | 'AGREED_TO_LOAN_AGREEMENT_DESC'
  | 'ACTIVE_ASC'
  | 'ACTIVE_DESC';

export const useLoanApplications = (
  stages?: STAGES_TYPE[],
  sort?: LOAN_APPLICATIONS_SORT_TYPE[],
): UseLoanApplicationsType => {
  const {data, loading, error} = useQuery(LOAN_APPLICATIONS, {
    variables: {stages: stages ?? [], sort: sort ?? ['CREATED_AT_UTC_DESC']},
  });

  const nodes = useEdges(data?.loanApplications);
  return {
    loanApplications: nodes,
    loading,
    error,
  };
};
