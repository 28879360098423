import {gql} from '@apollo/client';
import {LOAN_APPLICATION_FRAGMENT} from './loanApplication';

export const LOAN_APPLICATIONS = gql`
  ${LOAN_APPLICATION_FRAGMENT}
  query LoanApplications(
    $stages: [LoanApplicationStatusesType]
    $sort: [LoanApplicationObjectSortEnum]
  ) {
    loanApplications(stages: $stages, sort: $sort) {
      edges {
        node {
          ...LoanApplicationFields
        }
      }
    }
  }
`;
