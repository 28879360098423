import {gql} from '@apollo/client';
import {ALL_USERS} from '../queries/lenders';

export const DELETE_USER = gql`
  mutation DeleteUserMutation($id: ID) {
    deleteLender(id: $id) {
      success
      id
    }
  }
`;

export const DELETE_USER_OPTIONS = {
  update(
    cache: any,
    {
      data: {
        deleteLender: {id},
      },
    }: any,
  ) {
    try {
      const {
        lenders: {edges},
      } = cache.readQuery({query: ALL_USERS});
      const newEdges = edges.filter(({node}: any) => {
        console.log('node', node, id);
        return node.id !== id;
      });

      console.log('lenders', edges);
      console.log('new lenders', newEdges);

      cache.writeQuery({
        query: ALL_USERS,
        data: {
          lenders: {
            edges: newEdges,
          },
        },
      });
    } catch (e) {
      console.log('an error ocurred when updating delete mutation options', e);
    }
  },
};
