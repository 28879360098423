import React from 'react';
import {CheckCircleIcon, XIcon} from '@heroicons/react/outline';

type Props = {
  mobileNumber: string;
  verified: boolean;
};

export const MobileNumber = ({mobileNumber, verified}: Props) => {
  return (
    <div className="flex flex-col  w-full px-12 py-4">
      <div className="text-blue-600 font-medium text-lg my-2">
        Mobile Number
      </div>
      <div className="grid grid-cols-2 items-center">
        <div className="flex flex-col">
          <div className="font-medium text-black">Number</div>
          <div className="text-blue-600 text-lg mt-2">{mobileNumber}</div>
        </div>
        <div className="flex flex-col">
          ,<div className="font-medium text-black">Verified</div>
          <div className="h-10 w-10 flex items-center justify-center mt-2">
            {verified ? (
              <CheckCircleIcon className="text-green-600 w-full h-full" />
            ) : (
              <XIcon className="text-red-600 w-full h-full" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
