import React, {useCallback, useEffect, useState} from 'react';
import {DetailTitleBar} from '../../components/LoanApplicationDetail/components/DetailTitleBar';
import {LoanTerms} from '../../components/LoanApplicationDetail/components/LoanTerms';
import {MobileMoneyTransactions} from '../../components/LoanApplicationDetail/components/MobileMoneyTransactions';
import {AccountDetails} from '../../components/LoanApplicationDetail/components/AccountDetails';
import {Photos} from '../../components/LoanApplicationDetail/components/Photos';
import {GPSLocation} from '../../components/LoanApplicationDetail/components/GPSLocation';
import {Comments} from '../../components/LoanApplicationDetail/components/Comments';
import {VerificationLinks} from '../../components/LoanApplicationDetail/components/VerificationLinks';
import {NextOfKin} from '../../components/LoanApplicationDetail/components/NextOfKin';
import {MobileNumber} from '../../components/LoanApplicationDetail/components/MobileMoneyNumber';
import {
  DEFAULT_CURRENCY,
  PURPOSE_OF_LOAN,
  PURPOSE_OF_LOAN_CATEGORY,
  RATING_NUMBERS,
} from '../../constants/constants';
import {
  DetailPageProps,
  LoanApplicationDetail,
} from '../../components/LoanApplicationDetail/LoanApplicationDetail';
import {ItemsTable} from '../../components/ItemsTable';
import {DetailInLineTitle} from '../../components/LoanApplicationDetail/components/DetailInLineTitle';
import {DropDown} from '../../components/LoanApplicationDetail/components/DropDown';
import {formatMoney} from '../../utils/format-money';

type Props = {
  path?: string;
  applicationId: string;
};

const loanHistoryHeaders = [
  {name: 'Loan ID'},
  {name: 'Loan Status'},
  {name: 'Payment Status'},
  {name: 'Start Date'},
  {name: 'Due Date'},
  {name: 'Total Amount'},
  {name: 'Last Payment Date'},
  {name: 'Loan History'},
];

const nameMatchesHeaders = [
  {name: 'Loan ID'},
  {name: 'Business ID'},
  {name: 'Business Name'},
  {name: 'Profile Name'},
  {name: 'Approval Status'},
  {name: 'Phone number'},
  {name: 'Business Similarities'},
  {name: 'Profile Similarities'},
];

export const DisbursedLoanDetails = ({
  loanApplication,
  setPhotosLoading,
  setExpandedPhoto,
  photoStatuses,
  openCommentDialog,
  getComments,
  openContactAttemptDialog,
  getContactAttempts,
  setLoanApplicationCategory,
  loanApplicationCategory,
  handleSubmitLoanApplicationCategory,
  proposedAmount,
  setProposedAmount,
  handleSaveProposedAmount,
  openVisitAttemptsDialog,
  getVisitAttempts,
  getLoans,
}: DetailPageProps) => {
  const {headers: loanHeaders, items: loanItems} = getLoans();
  console.log('application', loanApplication);
  console.log('headers', loanHeaders, 'items', loanItems);
  return (
    <>
      <div className="flex flex-col bg-white rounded-xl mt-4 p-4 w-full shadow-md">
        <DetailTitleBar
          userName={loanApplication?.business?.borrower?.name}
          district={loanApplication?.business?.district}
          businessName={loanApplication?.business?.name}
          businessId={loanApplication?.business?.pk}
        />
        <div className="mt-8 w-full">
          <LoanTerms {...loanApplication?.loanQuote} />
        </div>
        <div className="mt-8 w-full border border-gray-300 rounded-lg p-4">
          <DetailInLineTitle title="Loan History" />
          <ItemsTable headers={loanHeaders} items={loanItems} />
        </div>
        {/* <div className="mt-8 w-full border border-gray-300 rounded-lg p-4">
          <DetailInLineTitle title="Business / Profile Name Matches" />
          <ItemsTable headers={nameMatchesHeaders} items={[]} />
        </div> */}
        <div className="mt-8 w-full grid grid-cols-2 gap-8">
          <MobileMoneyTransactions
            title="Mobile Money Transactions"
            transactions={[
              {
                title: 'Biggest amount received last week',
                amount:
                  loanApplication?.business?.transactions
                    .biggestMobileMoneyReceived,
              },
              {
                title: 'Smallest amount received last week',
                amount:
                  loanApplication?.business?.transactions
                    .smallestMobileMoneyReceived,
              },
              {
                title: 'Biggest amount sent last week',
                amount:
                  loanApplication?.business?.transactions
                    .biggestMobileMoneySent,
              },
              {
                title: 'Smallest amount sent last week',
                amount:
                  loanApplication?.business?.transactions
                    .smallestMobileMoneySent,
              },
            ]}
          />
          <MobileMoneyTransactions
            title="Business Sales"
            transactions={[
              {
                title: 'Total Sales Last Week',
                amount: loanApplication?.business?.transactions.salesLastWeek,
              },
              {
                title: 'Average sales in day',
                amount:
                  loanApplication?.business?.transactions.averageSalesInADay,
              },
              {
                title: 'Possible Weekly Payment',
                amount:
                  loanApplication?.business?.transactions.possibleWeeklyPayment,
              },
            ]}
          />
        </div>
        <div className="mt-8 w-full grid grid-cols-4 gap-8">
          <NextOfKin
            contacts={loanApplication?.business?.borrower?.nextOfKin}
          />
          <GPSLocation {...loanApplication?.business} />
        </div>
        <div className="mt-8 w-full grid grid-cols-4 gap-8">
          <AccountDetails loanApplication={loanApplication} />
        </div>
        <div className="mt-8 mx-4">
          <DetailInLineTitle title="Loan Application" />
          <div className="grid grid-cols-9 gap-2 w-full">
            <div className="col-span-3  mt-4">
              <DropDown
                placeholder=""
                title="Purpose of loan category"
                options={PURPOSE_OF_LOAN_CATEGORY}
                value={loanApplicationCategory?.purposeOfLoanCategory}
                onChange={value =>
                  setLoanApplicationCategory({
                    ...loanApplicationCategory,
                    purposeOfLoanCategory: value,
                  })
                }
              />
            </div>
            <div className="col-span-3  mt-4">
              <DropDown
                placeholder=""
                title="Purpose of loan"
                options={PURPOSE_OF_LOAN}
                value={loanApplicationCategory?.purposeOfLoan}
                onChange={value =>
                  setLoanApplicationCategory({
                    ...loanApplicationCategory,
                    purposeOfLoan: value,
                  })
                }
              />
            </div>
            <div className="col-span-1  mt-4">
              <DropDown
                placeholder=""
                title="Attitude"
                options={RATING_NUMBERS}
                value={'' + loanApplicationCategory?.attitude}
                onChange={value =>
                  setLoanApplicationCategory({
                    ...loanApplicationCategory,
                    attitude: parseInt(value),
                  })
                }
              />
            </div>
            <div className="col-span-1 mt-4  mt-4">
              <DropDown
                placeholder=""
                title="Honesty"
                options={RATING_NUMBERS}
                value={'' + loanApplicationCategory?.honesty}
                onChange={value =>
                  setLoanApplicationCategory({
                    ...loanApplicationCategory,
                    honesty: parseInt(value),
                  })
                }
              />
            </div>
            <div className="col-span-1  mt-4">
              <DropDown
                placeholder=""
                title="Availability"
                options={RATING_NUMBERS}
                value={'' + loanApplicationCategory?.availability}
                onChange={value =>
                  setLoanApplicationCategory({
                    ...loanApplicationCategory,
                    availability: parseInt(value),
                  })
                }
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className="text-align-end rounded px-8 py-2 bg-blue-600 text-gray-200 mt-8 mx-2"
              onClick={async e => await handleSubmitLoanApplicationCategory(e)}>
              Save
            </button>
          </div>
        </div>
        <div className="mt-8 mx-4">
          <DetailInLineTitle title="Adjust Loan Amount" />
          <div className="grid grid-cols-3 gap-4 mt-2">
            <div className="flex flex-col">
              <span className="text-gray-600 font-light mx-2 my-2">
                Requested Amount
              </span>
              <div className="rounded bg-gray-200 w-full py-2 px-4">
                {formatMoney(
                  loanApplication?.loanQuote?.principal,
                  DEFAULT_CURRENCY,
                )}
              </div>
            </div>

            <form className="flex flex-col">
              <label
                htmlFor="proposed-loan-amount"
                className="text-gray-600 font-light mx-2 my-2">
                Proposed Amount
              </label>
              <div className="flex flex-row items-center">
                <span className="px-4">{DEFAULT_CURRENCY} </span>
                <input
                  id="proposed-loan-amount"
                  type="number"
                  value={proposedAmount.amount}
                  className="border border-gray-300 rounded-md px-4 py-2"
                  onChange={e => setProposedAmount({amount: e.target.value})}
                />
              </div>
              {proposedAmount?.error && (
                <span className="py-2 text-red-400">
                  {proposedAmount?.error}
                </span>
              )}
            </form>
          </div>
          <div className="flex justify-end">
            <button
              className="text-align-end rounded px-8 py-2 bg-blue-600 text-gray-200 mt-8 mx-2"
              onClick={async e => await handleSaveProposedAmount(e)}>
              Save Proposed Loan Amount
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white rounded-xl mt-8 p-4 w-full shadow-md">
        <Photos
          photos={loanApplication?.business?.borrower?.photos}
          setLoading={setPhotosLoading}
          setExpandedPhoto={setExpandedPhoto}
          photoStatuses={photoStatuses}
          title="User Photos"
          photoGroup="BORROWER"
        />
        <Photos
          photos={loanApplication?.business?.photos}
          setLoading={setPhotosLoading}
          setExpandedPhoto={setExpandedPhoto}
          photoStatuses={photoStatuses}
          title="Business Photos"
        />
        <MobileNumber
          mobileNumber={loanApplication?.business?.borrower?.mobileMoneyNumber}
          verified={true}
        />
        <div className="w-full px-8 py-4 mt-4">
          <VerificationLinks />
        </div>
        <div className="w-full px-8 py-4 mt-4">
          <Comments
            title="Comments"
            headers={['Note', 'Date', 'Staff']}
            addComment={() =>
              openCommentDialog({
                open: true,
                loanApplicationId: loanApplication?.id,
              })
            }
            data={getComments()}
          />
        </div>
        <div className="w-full px-8 py-4 mt-4">
          <Comments
            title="Contact Attempts"
            headers={['Channel', 'Date', 'Staff']}
            data={getContactAttempts()}
            addComment={() =>
              openContactAttemptDialog({
                open: true,
                loanApplicationId: loanApplication?.id,
              })
            }
          />
        </div>
        <div className="w-full px-8 py-4 mt-4">
          <Comments
            title="Visit Attempts"
            headers={['Business Existence', 'Date', 'Staff']}
            data={getVisitAttempts()}
            addComment={() =>
              openVisitAttemptsDialog({
                open: true,
                loanApplicationId: loanApplication?.id,
              })
            }
          />
        </div>
        <div className="h-16" />
      </div>
    </>
  );
};

export const DisbursedLoanDetail = ({applicationId, path}: Props) => {
  return (
    <LoanApplicationDetail
      applicationId={applicationId}
      path={path}
      component={DisbursedLoanDetails}
    />
  );
};
