import React from 'react';
import {Dialog} from '../../../components/Dialog';
import {PhotosType} from '../../../hooks/useLoanApplication';

type Props = {
  photo?: PhotosType;
  open: boolean;
  onClose: () => void;
};

export const ExpandedPhotoDialog = ({photo, onClose, open}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={photo?.photoType ?? 'Photo'}
      btnText="Ok"
      description={photo?.status ?? 'Photo Status'}
      onSubmit={() => onClose()}>
      <div className="flex h-3/5 max-w-screen-lg w-auto flex items-center justify-center bg-red-200 m-2 rounded-lg cursor-pointer">
        <img
          src={photo?.url}
          className="w-auto max-w-screen-lg h-3/5 "
          style={{height: '520px'}}
        />
      </div>
    </Dialog>
  );
};
