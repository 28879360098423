import {gql} from '@apollo/client';

export const CURRENT_USER = gql`
  query CurrentLender {
    lender {
      id
      firstName
      lastName
      dateOfBirth
      phoneNumber
      email
      roles
      permissions
      adminPassword
    }
  }
`;
