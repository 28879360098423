import React, {SyntheticEvent, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {useLocation} from '@reach/router';
import {HomePageLayout} from '../HomePageLayout';
import {TitleBar} from '../TitleBar';
import {
  LoanApplicationType,
  PhotosType,
  useLoanApplication,
} from '../../hooks/useLoanApplication';
import {invalidInput, showToast, validInput} from '../Toast';
import {ExpandedPhotoDialog} from './components/ExpandedPhoto';
import {AddCommentDialog} from './components/AddCommentDialog';
import {AddContactAttemptDialog} from './components/AddContactAttemptDialog';
import {
  LOAN_APPLICATIONS_STATUSES,
  PHOTO_STATES,
  PHOTO_STATES_TYPE,
  VISIT_ATTEMPTS_OPTIONS,
  STAGES_TYPE,
  DEFAULT_CURRENCY,
} from '../../constants/constants';
import {useUpdateLoanApplicationStage} from '../../hooks/useUpdateLoanApplicationStage';
import {useResubmitPhoto} from '../../hooks/useResubmitPhoto';
import {
  LoanApplicationCategoryType,
  useUpdateLoanApplicationCategory,
} from '../../hooks/useUpdateLoanApplicationCategoryUpdate';
import {useUpdateLoanQuote} from '../../hooks/useUpdateLoanQuote';
import {AddVisitAttemptDialog} from './components/AddVisitAttemptDialog';
import {DisburseLoanDialog} from './components/DisburseLoanDialog';
import {useRequestLenderOtp} from '../../hooks/useRequestLenderOtp';
import {MutationFunctionOptions} from '@apollo/client';
import {formatMoney} from '../../utils/format-money';
import {routeToList} from './components/ActionButtons';

type Props = {
  path?: string;
  applicationId: string;
  component: React.ComponentType | React.ElementType;
};

type PhotoStatusesType = {
  [x: string]: {status: PHOTO_STATES_TYPE; photoType?: string} | undefined;
};

export type DetailPageProps = {
  setLoanDismissalReason: (reason: string | undefined) => void;
  getContactAttempts: () => {[x: string]: string | JSX.Element}[];
  getVisitAttempts: () => {[x: string]: string | JSX.Element}[];
  getComments: () => {[x: string]: string | JSX.Element}[];
  declineLoan: (e: SyntheticEvent) => void;
  photoResubmit: (e: SyntheticEvent) => void;
  updateStage: (e: SyntheticEvent, stage: STAGES_TYPE) => void;
  photoStatuses: PhotoStatusesType;
  loanApplication: LoanApplicationType;
  setPhotosLoading: (loading: boolean) => void;
  setExpandedPhoto: (photo: {open: boolean; photo?: PhotosType}) => void;
  openCommentDialog: (commentDialog: {
    open: boolean;
    loanApplicationId?: string;
  }) => void;
  openContactAttemptDialog: (contactAttemptsDialog: {
    open: boolean;
    loanApplicationId?: string;
  }) => void;
  openVisitAttemptsDialog: (visitAttemptsDialog: {
    open: boolean;
    loanApplicationId?: string;
  }) => void;
  openDisburseLoanDialog: (disburseDialog: {
    open: boolean;
    loanApplicationId?: string;
  }) => void;
  setLoanApplicationCategory: (category: LoanApplicationCategoryType) => void;
  loanApplicationCategory: LoanApplicationCategoryType;
  handleSubmitLoanApplicationCategory: (e: SyntheticEvent) => void;
  proposedAmount: {amount?: string; error?: string};
  setProposedAmount: (proposedAmount: {
    amount?: string;
    error?: string;
  }) => void;
  handleSaveProposedAmount: (e: SyntheticEvent) => void;
  requestOtp: (options: MutationFunctionOptions | undefined) => void;
  getLoans: () => {headers: any[]; items: any[]};
};

export const LoanApplicationDetail = ({
  applicationId,
  component: Component,
}: Props) => {
  const {pathname} = useLocation();
  const {loanApplication, error, loading} = useLoanApplication(applicationId);
  const [photosLoading, setPhotosLoading] = useState<boolean>(false);
  const [expandedPhoto, setExpandedPhoto] = useState<{
    open: boolean;
    photo?: PhotosType;
  }>({open: false});

  const {resubmitPhoto, loading: resubmittingPhoto} = useResubmitPhoto();
  const [loanDismissalReason, setLoanDismissalReason] = useState<
    string | undefined
  >();

  const {loading: updatingLoanQuote, updateLoanQuote} = useUpdateLoanQuote();
  const {requestOtp, loading: requestingOtp} = useRequestLenderOtp();
  const [loanApplicationCategory, setLoanApplicationCategory] =
    useState<LoanApplicationCategoryType>({});

  const [proposedAmount, setProposedAmount] = useState<{
    amount?: string;
    error?: string;
  }>({amount: ''});

  const {
    loading: updatingLoanApplicationCategory,
    updateLoanApplicationCategory,
  } = useUpdateLoanApplicationCategory();

  const [photoStatuses, setPhotoStatuses] = useState<PhotoStatusesType>({});
  const {loading: updatingLoanApplicationStage, updateLoanApplicationStage} =
    useUpdateLoanApplicationStage();

  const [commentDialog, openCommentDialog] = useState<{
    open: boolean;
    loanApplicationId?: string;
  }>({open: false});
  const [contactAttemptDialog, openContactAttemptDialog] = useState<{
    open: boolean;
    loanApplicationId?: string;
  }>({open: false});
  const [visitAttemptDialog, openVisitAttemptsDialog] = useState<{
    open: boolean;
    loanApplicationId?: string;
  }>({open: false});
  const [disburseLoanDialog, openDisburseLoanDialog] = useState<{
    open: boolean;
    loanApplicationId?: string;
  }>({open: false});
  const [commentDialogLoading, setCommentDialogLoading] =
    useState<boolean>(false);
  const [contactAttemptDialogLoading, setContactAttemptDialogLoading] =
    useState<boolean>(false);

  const [visitAttemptsDialogLoading, setVisitAttemptsDialogLoading] =
    useState<boolean>(false);

  const [disburseLoanLoading, setDisburseLoanLoading] =
    useState<boolean>(false);
  const getComments = () => {
    if (!loanApplication?.comments) return [];
    return loanApplication?.comments?.map(comment => ({
      comment: comment.comment,
      date: new Date(comment.createdAtUtc).toLocaleDateString(),
      staff: `${comment.staff.firstName} ${comment.staff.lastName}`,
    }));
  };

  const getContactAttempts = () => {
    if (!loanApplication?.contactAttempts) return [];
    return loanApplication?.contactAttempts?.map(attempt => ({
      channel: attempt.channel,
      date: new Date(attempt.createdAtUtc).toLocaleDateString(),
      staff: `${attempt.staff.firstName} ${attempt.staff.lastName}`,
      success: attempt.contactedSuccessfully ? (
        <span className="text-green-600">Contacted Successfully</span>
      ) : (
        <span className="text-red-600">Contacted Unsuccessfully</span>
      ),
    }));
  };

  const getVisitAttempts = () => {
    if (!loanApplication?.visitAttempts) return [];
    return loanApplication?.visitAttempts?.map(attempt => ({
      status: (
        <span
          className={`${
            attempt.status === VISIT_ATTEMPTS_OPTIONS.CONFIRMED
              ? 'text-green-600'
              : 'text-red-600'
          }`}>
          {attempt.status}
        </span>
      ),
      date: new Date(attempt.createdAtUtc).toLocaleDateString(),
      staff: `${attempt.staff.firstName} ${attempt.staff.lastName}`,
    }));
  };

  const getLoans = (): {headers: any[]; items: any[]} => {
    const headers = [
      {name: 'Loan ID'},
      {name: 'Loan Status'},
      {name: 'Start Date'},
      {name: 'Due Date'},
      {name: 'Total Amount'},
      {name: 'Last Payment Date'},
    ];

    const items = !loanApplication?.business?.loans
      ? []
      : loanApplication?.business?.loans?.map(loan => ({
          id: loan.pk,
          status: loan.loanStatus.status,
          startDate: new Date(loan.createdAtUtc).toLocaleDateString(),
          dueDate: dayjs(loan.loanEndTimeUtc).format('DD/MM/YYYY'),
          amount: formatMoney(loan.amount, DEFAULT_CURRENCY),
          lastPaymentDate:
            loan.loanPayments?.length > 0
              ? formatMoney(loan?.loanPayments[0].amountPaid, DEFAULT_CURRENCY)
              : '-',
        }));
    return {headers, items};
  };

  const handleSubmitLoanApplicationCategory = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (
      !loanApplicationCategory.attitude ||
      loanApplicationCategory.attitude === 0
    ) {
      invalidInput('Update Borrower "Attitude" before you can save');
      return;
    }
    if (
      !loanApplicationCategory.honesty ||
      loanApplicationCategory.honesty === 0
    ) {
      invalidInput('Update Borrower "Honesty" before you can save');
      return;
    }
    if (
      !loanApplicationCategory.availability ||
      loanApplicationCategory.availability === 0
    ) {
      invalidInput('Update Borrower "Availability" before you can save');
      return;
    }
    if (
      !loanApplicationCategory.purposeOfLoanCategory ||
      loanApplicationCategory.purposeOfLoanCategory === ''
    ) {
      invalidInput(
        'Update Borrower "Purpose of Loan Category" before you can save',
      );
      return;
    }
    if (
      !loanApplicationCategory.purposeOfLoan ||
      loanApplicationCategory.purposeOfLoan === ''
    ) {
      invalidInput('Update Borrower "Purpose of Loan" before you can save');
      return;
    }
    try {
      await updateLoanApplicationCategory({
        variables: {
          loanApplicationId: loanApplication?.id,
          category: loanApplicationCategory,
        },
      });
      validInput('Done updating loan application category');
    } catch (e: any) {
      console.error('Error when updating loan application category', e);
      invalidInput(`An error occurred when updating loan application category`);
    }
  };

  const handleSaveProposedAmount = async (e: SyntheticEvent) => {
    if (!proposedAmount?.amount || proposedAmount?.amount === '') {
      setProposedAmount({
        ...proposedAmount,
        error: 'Please enter proposed amount',
      });
      invalidInput('Please enter proposed amount before trying to save');
      return;
    }

    try {
      await updateLoanQuote({
        variables: {
          quote: {proposedAmount: proposedAmount.amount},
          loanApplicationId: loanApplication?.id,
        },
      });
      validInput('Done updating proposed amount');
    } catch (e: any) {
      console.error('An error occurred when updating proposed amount', e);
      invalidInput('An error occurred when updating proposed amount');
    }
  };

  useEffect(() => {
    if (error) {
      console.error('error occurred when loading loan application', error);
      showToast({
        open: true,
        message: 'Error occurred when loading loan application',
        positive: false,
      });
    }
  }, [error]);

  useEffect(() => {
    const statuses = {} as any;
    if (loanApplication?.business?.borrower?.photos) {
      loanApplication?.business?.borrower?.photos?.map(photo => {
        if (photo?.id) {
          statuses[photo.id] = {};
          statuses[photo.id].status = photo?.status;
          statuses[photo.id].photoType = photo?.photoType;
        }
      });
    }
    if (loanApplication?.business?.photos) {
      loanApplication?.business?.photos?.map(photo => {
        if (photo?.id) {
          statuses[photo.id] = {};
          statuses[photo.id].status = photo?.status;
          statuses[photo.id].photoType = photo?.photoType;
        }
      });
    }
    setPhotoStatuses(statuses);
  }, [
    loanApplication?.business?.borrower?.photos,
    loanApplication?.business?.photos,
  ]);

  useEffect(() => {
    if (
      loanApplication?.stage?.stage === LOAN_APPLICATIONS_STATUSES.SUBMITTED
    ) {
      (async () => {
        try {
          await updateLoanApplicationStage({
            variables: {
              stage: {
                status: LOAN_APPLICATIONS_STATUSES.PRE_SCREENING,
              },
              loanApplicationId: loanApplication?.id,
            },
          });
          showToast({
            open: true,
            message: 'Loan has status been updated to prescreening',
            positive: true,
          });
        } catch (e: any) {
          console.error('Error occurred when updating loan application status');
        }
      })();
    }
  }, [loanApplication?.stage?.stage]);

  useEffect(() => {
    if (
      Object.keys(loanApplicationCategory).length === 0 &&
      loanApplication?.category
    ) {
      setLoanApplicationCategory({
        attitude: loanApplication?.category?.attitude,
        availability: loanApplication?.category?.availability,
        honesty: loanApplication?.category?.honesty,
        purposeOfLoanCategory: loanApplication?.category?.purposeOfLoanCategory,
        purposeOfLoan: loanApplication?.category?.purposeOfLoan,
      });
    }
  }, [loanApplication?.category]);

  useEffect(() => {
    if (
      proposedAmount.amount === '' &&
      loanApplication?.loanQuote?.proposedAmount
    ) {
      setProposedAmount({
        amount: '' + loanApplication?.loanQuote?.proposedAmount,
      });
    }
  }, [loanApplication?.loanQuote?.proposedAmount]);

  const completedReview = () => {
    const notReviewed = Object.values(photoStatuses).filter(photo => {
      return photo?.status === PHOTO_STATES.UNKNOWN;
    });
    if (notReviewed.length > 0) {
      showToast({
        open: true,
        message: 'Review all photos before you can continue',
        positive: false,
      });
      return false;
    }
    return true;
  };

  const declineLoan = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!completedReview()) return;
    if (!loanDismissalReason || loanDismissalReason === '') {
      showToast({
        open: true,
        message:
          'Please select dismissal reason before submitting for dismissal',
        positive: false,
      });
      return;
    }
    try {
      await updateLoanApplicationStage({
        variables: {
          stage: {
            status: LOAN_APPLICATIONS_STATUSES.DECLINED,
            comment: loanDismissalReason,
          },
          loanApplicationId: loanApplication?.id,
        },
      });
      showToast({
        open: true,
        message: 'Loan has been declined',
        positive: true,
      });
      routeToList(pathname);
    } catch (e: any) {
      console.error(
        'error occurred when updating loan application stage',
        e.message,
      );
      showToast({
        open: true,
        message: `Error when updating loan application status: ${e.message}`,
        positive: false,
      });
    }
  };

  const updateStage = async (e: SyntheticEvent, stage: STAGES_TYPE) => {
    e.preventDefault();
    if (!completedReview()) return;
    try {
      await updateLoanApplicationStage({
        variables: {
          stage: {
            status: stage,
          },
          loanApplicationId: loanApplication?.id,
        },
      });
      showToast({
        open: true,
        message: `Loan stage has been updated to ${stage}`,
        positive: true,
      });
      routeToList(pathname);
    } catch (e: any) {
      console.error(
        'error occurred when updating loan application stage',
        e.message,
      );
      showToast({
        open: true,
        message: `Error when updating loan application stage: ${e.message}`,
        positive: false,
      });
    }
  };

  const photoResubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!completedReview()) return;

    const resubmit = Object.values(photoStatuses).filter(photo => {
      console.log(photo);
      return photo?.status === PHOTO_STATES.BAD;
    });

    if (resubmit.length === 0) {
      showToast({
        open: true,
        message: 'No photos to resubmit',
        positive: false,
      });
      return;
    }

    try {
      await resubmitPhoto({
        variables: {
          photoTypes: resubmit.map(photo => photo?.photoType),
          loanApplicationId: loanApplication?.id,
        },
      });
      showToast({
        open: true,
        message: 'bad photos marked for resubmission',
        positive: true,
      });
      routeToList(pathname);
    } catch (e: any) {
      console.error('error occurred when resubmitting photo', e.message);
      showToast({
        open: true,
        message: `Error when resubmitting photo: ${e.message}`,
        positive: false,
      });
    }
  };

  const canAssignSelf = (): boolean => {
    return false;
  };
  return (
    <HomePageLayout
      loading={
        loading ||
        photosLoading ||
        commentDialogLoading ||
        updatingLoanApplicationStage ||
        resubmittingPhoto ||
        contactAttemptDialogLoading ||
        updatingLoanApplicationCategory ||
        updatingLoanQuote ||
        visitAttemptsDialogLoading ||
        disburseLoanLoading ||
        requestingOtp
      }>
      <TitleBar
        title="Application Details"
        description="Prescreening/application/details"
        disabled={!canAssignSelf()}
        // update={async () => await updateStage()}
      />
      <ExpandedPhotoDialog
        open={expandedPhoto.open}
        photo={expandedPhoto.photo}
        onClose={() => setExpandedPhoto({open: false})}
      />
      <AddCommentDialog
        open={commentDialog.open}
        onClose={() => openCommentDialog({open: false})}
        setLoading={setCommentDialogLoading}
        loanApplicationId={commentDialog.loanApplicationId}
      />
      <AddContactAttemptDialog
        open={contactAttemptDialog.open}
        onClose={() => openContactAttemptDialog({open: false})}
        setLoading={setContactAttemptDialogLoading}
        loanApplicationId={contactAttemptDialog.loanApplicationId}
      />
      <AddVisitAttemptDialog
        open={visitAttemptDialog.open}
        onClose={() => openVisitAttemptsDialog({open: false})}
        setLoading={setVisitAttemptsDialogLoading}
        loanApplicationId={visitAttemptDialog.loanApplicationId}
      />
      <DisburseLoanDialog
        open={disburseLoanDialog.open}
        onClose={() => openDisburseLoanDialog({open: false})}
        setLoading={setDisburseLoanLoading}
        loanApplicationId={disburseLoanDialog.loanApplicationId}
      />
      <div className="flex flex-col w-full p-4">
        <Component
          setLoanDismissalReason={setLoanDismissalReason}
          getContactAttempts={getContactAttempts}
          getComments={getComments}
          declineLoan={declineLoan}
          photoResubmit={photoResubmit}
          updateStage={updateStage}
          photoStatuses={photoStatuses}
          loanApplication={loanApplication}
          setPhotosLoading={setPhotosLoading}
          setExpandedPhoto={setExpandedPhoto}
          openCommentDialog={openCommentDialog}
          loanApplicationCategory={loanApplicationCategory}
          setLoanApplicationCategory={setLoanApplicationCategory}
          handleSubmitLoanApplicationCategory={
            handleSubmitLoanApplicationCategory
          }
          handleSaveProposedAmount={handleSaveProposedAmount}
          proposedAmount={proposedAmount}
          openContactAttemptDialog={openContactAttemptDialog}
          setProposedAmount={setProposedAmount}
          openVisitAttemptsDialog={openVisitAttemptsDialog}
          openDisburseLoanDialog={openDisburseLoanDialog}
          getVisitAttempts={getVisitAttempts}
          requestOtp={requestOtp}
          getLoans={getLoans}
        />
      </div>
    </HomePageLayout>
  );
};
