import {ApolloError, useQuery} from '@apollo/client';
import {LOAN_STATUES_TYPE} from '../constants/constants';
import {ALL_LOANS} from '../queries/loans';
import {useEdges} from './useEdges';
import {LoanType} from './useLoanApplication';

type UseLoanType = {
  loading: boolean;
  error: ApolloError | undefined;
  loans: LoanType[] | undefined;
};

type LoanSortType =
  | 'ID_ASC'
  | 'ID_DESC'
  | 'CREATED_AT_UTC_ASC'
  | 'CREATED_AT_UTC_DESC'
  | 'BUSINESS_ID_ASC'
  | 'BUSINESS_ID_DESC'
  | 'CURRENCY_ASC'
  | 'CURRENCY_DESC'
  | 'LOAN_PURPOSE_ASC'
  | 'LOAN_PURPOSE_DESC'
  | 'ACCEPTED_TERMS_ASC'
  | 'ACCEPTED_TERMS_DESC'
  | 'AGREED_TO_LOAN_AGREEMENT_ASC'
  | 'AGREED_TO_LOAN_AGREEMENT_DESC'
  | 'ACTIVE_ASC'
  | 'ACTIVE_DESC';

export const useAllLoans = (
  statuses?: LOAN_STATUES_TYPE[],
  sort?: LoanSortType[],
): UseLoanType => {
  const {data, loading, error} = useQuery(ALL_LOANS, {
    variables: {sort: sort ?? ['CREATED_AT_UTC_DESC'], statuses},
  });
  const nodes = useEdges(data?.loans);
  return {
    loans: nodes,
    loading,
    error,
  };
};
