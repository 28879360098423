import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {Checkbox} from '../../../components/checkbox';
import {Dialog} from '../../../components/Dialog';
import {showToast} from '../../../components/Toast';
import {useCreateContactAttempt} from '../../../hooks/useCreateContactAttempt';

type Props = {
  setLoading: (loading: boolean) => void;
  loanApplicationId?: string;
  open: boolean;
  onClose: () => void;
};

export const AddContactAttemptDialog = ({
  setLoading,
  onClose,
  open,
  loanApplicationId,
}: Props) => {
  const [formData, setFormData] = useState<{
    channel: string;
    contactedSuccessfully?: boolean;
  }>({channel: '', contactedSuccessfully: false});
  const [inputError, setInputError] = useState<string | undefined>();

  const {
    contactAttempt: contactAttemptCreated,
    createContactAttempt,
    loading,
  } = useCreateContactAttempt();

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const setChannel = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, channel: e.target.value});
    if (inputError) setInputError(undefined);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    let valid = true;
    try {
      //validate data

      if (!formData.channel || formData.channel === '') {
        valid = false;
      }
      if (valid) {
        //submit data
        console.log('submitting correct data', formData);
        await createContactAttempt({
          variables: {
            contactAttempt: {...formData, loanApplicationId},
          },
        });
        showToast({
          open: true,
          message: 'Contact Attempt added successfully',
          positive: true,
        });
      }
    } catch (e: any) {
      showToast({
        open: true,
        message: `An Error occurred when saving contact Attempt: ${e.message}`,
        positive: false,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={'Add Contact Attempt'}
      btnText="Submit"
      description="Add new Contact Attempt to the loan application"
      onSubmit={async e => await onSubmit(e)}>
      <>
        <label htmlFor="comment-input">Channel</label>
        <input
          id="comment-input"
          type="text"
          onChange={setChannel}
          className="border border-gray-300 rounded-md px-4 py-2 my-4"
        />
        <Checkbox
          title="Contacted Successfully"
          checked={formData.contactedSuccessfully ?? false}
          setChecked={checked =>
            setFormData({...formData, contactedSuccessfully: checked})
          }
        />
        {inputError && (
          <span className="py-2 px-4 text-red-600">{inputError}</span>
        )}
      </>
    </Dialog>
  );
};
