import React from 'react';
import {DEFAULT_CURRENCY} from '../../../constants/constants';
import {formatMoney} from '../../../utils/format-money';
import {TransactionsTicket} from './TransactionsTicket';

type TransactionsType = {
  title: string;
  amount: number;
};
type Props = {
  transactions: TransactionsType[];
  title: string;
};
export const MobileMoneyTransactions = ({transactions, title}: Props) => {
  return (
    <div className="p-4 border border-gray-300 rounded-xl w-full">
      <div className="text-blue-600 font-medium text-lg my-2">{title}</div>
      <div className="grid grid-cols-2 w-full">
        {transactions.map(transaction => (
          <TransactionsTicket
            key={transaction.title}
            title={transaction.title}
            description={formatMoney(transaction.amount, DEFAULT_CURRENCY)}
          />
        ))}
      </div>
    </div>
  );
};
