import {ApolloError, useQuery} from '@apollo/client';
import {ROLES_QUERY} from '../queries/roles';
import {useEdges} from './useEdges';

export type RolesType = {
  id: string;
  name: string;
  createdAtUtc: string;
  permissions: string[];
};

type UseRolesType = {
  loading: boolean;
  error: ApolloError | undefined;
  roles: RolesType[] | undefined;
};

export const useRoles = (): UseRolesType => {
  const {data, loading, error} = useQuery(ROLES_QUERY);
  const nodes = useEdges(data?.roles);
  return {
    roles: nodes,
    loading,
    error,
  };
};
