import React from 'react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/outline';
import ReactDropdown from 'react-dropdown';
import {DetailTitleBar} from '../../components/LoanApplicationDetail/components/DetailTitleBar';
import {LoanTerms} from '../../components/LoanApplicationDetail/components/LoanTerms';
import {MobileMoneyTransactions} from '../../components/LoanApplicationDetail/components/MobileMoneyTransactions';
import {AccountDetails} from '../../components/LoanApplicationDetail/components/AccountDetails';
import {Photos} from '../../components/LoanApplicationDetail/components/Photos';
import {ActionButtons} from '../../components/LoanApplicationDetail/components/ActionButtons';
import {GPSLocation} from '../../components/LoanApplicationDetail/components/GPSLocation';
import {Comments} from '../../components/LoanApplicationDetail/components/Comments';
import {VerificationLinks} from '../../components/LoanApplicationDetail/components/VerificationLinks';
import {NextOfKin} from '../../components/LoanApplicationDetail/components/NextOfKin';
import {MobileNumber} from '../../components/LoanApplicationDetail/components/MobileMoneyNumber';
import {
  DISMISSAL_REASONS,
  LOAN_APPLICATIONS_STATUSES,
} from '../../constants/constants';
import {
  DetailPageProps,
  LoanApplicationDetail,
} from '../../components/LoanApplicationDetail/LoanApplicationDetail';

type Props = {
  path?: string;
  applicationId: string;
};

export const PrescreeningDetails = ({
  loanApplication,
  setPhotosLoading,
  setExpandedPhoto,
  photoStatuses,
  openCommentDialog,
  getComments,
  openContactAttemptDialog,
  getContactAttempts,
  setLoanDismissalReason,
  updateStage,
  photoResubmit,
}: DetailPageProps) => {
  return (
    <>
      <div className="flex flex-col bg-white rounded-xl mt-4 p-4 w-full shadow-md">
        <DetailTitleBar
          userName={loanApplication?.business?.borrower?.name}
          district={loanApplication?.business?.district}
          businessName={loanApplication?.business?.name}
          businessId={loanApplication?.business?.pk}
        />
        <div className="mt-8 w-full">
          <LoanTerms {...loanApplication?.loanQuote} />
        </div>
        <div className="mt-8 w-full grid grid-cols-2 gap-8">
          <MobileMoneyTransactions
            title="Mobile Money Transactions"
            transactions={[
              {
                title: 'Biggest amount received last week',
                amount:
                  loanApplication?.business?.transactions
                    .biggestMobileMoneyReceived,
              },
              {
                title: 'Smallest amount received last week',
                amount:
                  loanApplication?.business?.transactions
                    .smallestMobileMoneyReceived,
              },
              {
                title: 'Biggest amount sent last week',
                amount:
                  loanApplication?.business?.transactions
                    .biggestMobileMoneySent,
              },
              {
                title: 'Smallest amount sent last week',
                amount:
                  loanApplication?.business?.transactions
                    .smallestMobileMoneySent,
              },
            ]}
          />
          <MobileMoneyTransactions
            title="Business Sales"
            transactions={[
              {
                title: 'Total Sales Last Week',
                amount: loanApplication?.business?.transactions.salesLastWeek,
              },
              {
                title: 'Average sales in day',
                amount:
                  loanApplication?.business?.transactions.averageSalesInADay,
              },
              {
                title: 'Possible Weekly Payment',
                amount:
                  loanApplication?.business?.transactions.possibleWeeklyPayment,
              },
            ]}
          />
        </div>
        <div className="mt-8 w-full grid grid-cols-4 gap-8">
          <NextOfKin
            contacts={loanApplication?.business?.borrower?.nextOfKin}
          />
          <GPSLocation {...loanApplication?.business} />
        </div>
        <div className="mt-8 w-full grid grid-cols-4 gap-8">
          <AccountDetails loanApplication={loanApplication} />
        </div>
      </div>
      <div className="flex flex-col bg-white rounded-xl mt-8 p-4 w-full shadow-md">
        <Photos
          photos={loanApplication?.business?.borrower?.photos}
          setLoading={setPhotosLoading}
          setExpandedPhoto={setExpandedPhoto}
          photoStatuses={photoStatuses}
          title="User Photos"
          photoGroup="BORROWER"
        />
        <Photos
          photos={loanApplication?.business?.photos}
          setLoading={setPhotosLoading}
          setExpandedPhoto={setExpandedPhoto}
          photoStatuses={photoStatuses}
          title="Business Photos"
        />
        <MobileNumber
          mobileNumber={loanApplication?.business?.borrower?.mobileMoneyNumber}
          verified={true}
        />
        <div className="w-full px-8 py-4 mt-4">
          <VerificationLinks />
        </div>
        <div className="w-full px-8 py-4 mt-4">
          <Comments
            title="Comments"
            headers={['Note', 'Date', 'Staff']}
            addComment={() =>
              openCommentDialog({
                open: true,
                loanApplicationId: loanApplication?.id,
              })
            }
            data={getComments()}
          />
        </div>
        <div className="w-full px-8 py-4 mt-4">
          <Comments
            title="Contact Attempts"
            headers={['Channel', 'Date', 'Staff', '']}
            data={getContactAttempts()}
            addComment={() =>
              openContactAttemptDialog({
                open: true,
                loanApplicationId: loanApplication?.id,
              })
            }
          />
        </div>
        <div className="w-full px-8 mt-8 cursor pointer">
          <ReactDropdown
            className="flex flex-col max-w-xl"
            controlClassName="flex flex-row bg-gray-100 rounded-lg px-6 py-2 items-center justify-between w-full"
            options={DISMISSAL_REASONS}
            placeholder="dismissal reason"
            arrowClassName="bg-red-200"
            menuClassName="px-6 cursor-pointer bg-gray-50"
            onChange={value => setLoanDismissalReason(value.value)}
            arrowClosed={<ChevronDownIcon className="w-8 h-8" />}
            arrowOpen={<ChevronUpIcon className="w-8 h-8" />}
          />
        </div>
        <div className="w-full px-8 py-4 mt-8">
          <ActionButtons
            dismissal
            stage={LOAN_APPLICATIONS_STATUSES.DUE_DILIGENCE}
            updateStage={updateStage}
            clientResubmit={async e => await photoResubmit(e)}
          />
        </div>
        <div className="h-16" />
      </div>
    </>
  );
};

export const PrescreeningDetail = ({applicationId, path}: Props) => {
  return (
    <LoanApplicationDetail
      applicationId={applicationId}
      path={path}
      component={PrescreeningDetails}
    />
  );
};
