import React, {useEffect} from 'react';
import {HomePageLayout} from '../../components/HomePageLayout';
import {TitleBar} from '../../components/TitleBar';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {navigate} from 'gatsby';
import {showToast} from '../../components/Toast';
import {
  DEFAULT_CURRENCY,
  LOAN_APPLICATIONS_STATUSES,
} from '../../constants/constants';
import {LoanApplicationType} from '../../hooks/useLoanApplication';
import {formatMoney} from '../../utils/format-money';
import {useHasPermission} from '../../hooks/useHasPermission';
import AccessDenied from '../../components/AccessDenied';

type Props = {
  path?: string;
};

export const Prescreening = ({}: Props) => {
  const headers = [
    {name: 'Business Name'},
    {name: 'Client Name'},
    {name: 'Date'},
    {name: 'Amount applied'},
    {name: 'Phone Number'},
  ];

  const getItems = (loanApplications?: LoanApplicationType[]) => {
    if (!loanApplications || !Array.isArray(loanApplications)) {
      return [];
    }
    return loanApplications.map(application => {
      return {
        businessName: (
          <div
            className="px-2 text-start text-blue-500 cursor-pointer"
            onClick={() => {
              navigate(`${application.id}/detail`);
            }}>
            {application.business.name}
          </div>
        ),
        clientName: application.business.borrower.name,
        date: new Date(application.createdAtUtc).toLocaleDateString(),
        amount: formatMoney(application.loanQuote.amount, DEFAULT_CURRENCY),
        phone: application.business.borrower.phoneNumber,
        others: <div className="cursor-pointer">...</div>,
      };
    });
  };

  const {
    loanApplications,
    error: loanApplicationsError,
    loading: loadingLoanApplications,
  } = useLoanApplications([
    LOAN_APPLICATIONS_STATUSES.PRE_SCREENING,
    LOAN_APPLICATIONS_STATUSES.SUBMITTED,
  ]);

  useEffect(() => {
    if (loanApplicationsError) {
      console.error('loan applications', loanApplicationsError);
      showToast({
        open: true,
        message: 'Error occurred when loading loan applications',
        positive: false,
      });
    }
  }, [loanApplicationsError]);

  const hasPerm = useHasPermission();

  if (!hasPerm('/app/pre-screening')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loadingLoanApplications}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Pre Screening" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <ItemsTable headers={headers} items={getItems(loanApplications)} />
        </div>
      </div>
    </HomePageLayout>
  );
};
