import {useEffect} from 'react';
import {useLocation} from '@reach/router';

type Props = {
  setTab: (tab: string) => void;
  defaultTab: string;
  callback?: (tab: string) => void;
  tab: string;
};
export const useActiveTab = (
  {setTab: setTab, defaultTab, tab}: Props,
  callback?: (tab: string) => void,
) => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab') || defaultTab;
    setTab(tab);
    callback && callback(tab);
  }, []);

  useEffect(() => {
    window.history.pushState({}, '', `?tab=${tab}`);
  }, [tab]);
};
