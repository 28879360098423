import {gql} from '@apollo/client';
import {LOAN_FIELDS} from '../mutations/disburseLoan';
import {LENDER_FIELDS} from './lenders';

export const BUSINESS_FIELDS = gql`
  fragment BusinessFields on BusinessObject {
    id
    pk
    name
    district
    usesMobileMoneyTransactions
    location
    numberOfEmployees
    transactions {
      id
      biggestMobileMoneyReceived
      smallestMobileMoneyReceived
      biggestMobileMoneySent
      smallestMobileMoneySent
      averageSalesInADay
      possibleWeeklyPayment
      salesLastWeek
    }
    type
    age
    numberOfEmployees
    borrower {
      id
      nin
      gender
      name
      dateOfBirth
      nationality
      mobileMoneyNumber
      nextOfKin {
        id
        name
        relationship
        phone
      }
      phoneNumber
      nationalIdName
      photos {
        id
        url
        photoType
        status
        comment
      }
    }
    location
    photos {
      id
      url
      photoType
      status
      comment
    }
  }
`;

export const LOAN_APPLICATION_FRAGMENT = gql`
  ${LENDER_FIELDS}
  ${LOAN_FIELDS}
  ${BUSINESS_FIELDS}
  fragment LoanApplicationFields on LoanApplicationObject {
    id
    createdAtUtc
    acceptedTerms
    comments {
      id
      createdAtUtc
      comment
      staff {
        ...LenderFields
      }
    }
    contactAttempts {
      id
      createdAtUtc
      channel
      staff {
        ...LenderFields
      }
      contactedSuccessfully
    }
    visitAttempts {
      id
      createdAtUtc
      dateVisited
      status
      staff {
        ...LenderFields
      }
    }
    loanQuote {
      id
      pk
      amount
      principal
      interest
      interestAmount
      createdAtUtc
      proposedAmount
      termDays
    }
    stage {
      id
      staffId
      createdAtUtc
      stage
      staff {
        ...LenderFields
      }
    }
    category {
      id
      attitude
      availability
      honesty
      purposeOfLoanCategory
      purposeOfLoan
    }
    business {
      ...BusinessFields
      loans {
        ...LoanFields
      }
    }
    loan {
      ...LoanFields
    }
  }
`;

export const LOAN_APPLICATION_QUERY = gql`
  ${LOAN_APPLICATION_FRAGMENT}
  query GetLoanApplication($id: ID!) {
    loanApplication(id: $id) {
      ...LoanApplicationFields
    }
  }
`;
