import {navigate} from 'gatsby';
import dayjs from 'dayjs';
import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {DEFAULT_CURRENCY, LOAN_STATUSES} from '../../constants/constants';
import {useAllLoans} from '../../hooks/useAllLoans';
import {LoanType} from '../../hooks/useLoanApplication';
import {formatMoney} from '../../utils/format-money';
import {useActiveTab} from '../../hooks/useActiveTab';

type Props = {
  path?: string;
};

type TabsType = 'Current' | 'Fully Paid' | 'Arrears' | 'Pending';

const CURRENT: 'Current' = 'Current';
const PAID_UP: 'Fully Paid' = 'Fully Paid';
const ARREARS: 'Arrears' = 'Arrears';
const PENDING: 'Pending' = 'Pending';

const currentHeaders = [
  {name: 'ID'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Due Date'},
  {name: 'Loan Amount'},
  {name: 'Out Standing Amount'},
  {name: 'Phone Number'},
];

const paidUpHeaders = [...currentHeaders];
const arrearsHeaders = [...currentHeaders];
const pendingHeaders = [...currentHeaders];

export const Portfolio = ({}: Props) => {
  const {
    loans: current,
    error: currentLoansError,
    loading: currentLoansLoading,
  } = useAllLoans([LOAN_STATUSES.CURRENT]);

  const {
    loans: pending,
    error: pendingError,
    loading: pendingLoading,
  } = useAllLoans([LOAN_STATUSES.PENDING]);

  const {
    loans: arrears,
    error: arrearsError,
    loading: arrearsLoading,
  } = useAllLoans([LOAN_STATUSES.IN_ARREARS]);

  const {
    loans: paidUp,
    error: paidUpErrors,
    loading: paidUpLoading,
  } = useAllLoans([LOAN_STATUSES.PAIN_IN_FULL]);

  useEffect(() => {
    if (currentLoansError) {
      console.error('loans', currentLoansError);
      invalidInput('Error occurred when loading loan active current loans');
    }
    if (pendingError) {
      console.error('loans', pendingError);
      invalidInput('Error occurred when loading pending loans');
    }
    if (arrearsError) {
      console.error('loans', arrearsError);
      invalidInput('Error occurred when loading arrears loans');
    }
    if (paidUpErrors) {
      console.error('loans', paidUpErrors);
      invalidInput('Error occurred when loading paidUp loans');
    }
  }, [currentLoansError, pendingError, arrearsError, paidUpErrors]);

  const formatLoans = useCallback(
    (loans: LoanType[] | undefined | LoanType) => {
      if (!loans || !Array.isArray(loans)) return [];
      return loans.map(loan => {
        return {
          id: loan.pk,
          businessName: (
            <div
              className="px-2 text-start text-blue-500 cursor-pointer"
              onClick={() => {
                navigate(`${loan.id}/detail`);
              }}>
              {loan.business.name}
            </div>
          ),
          clientName: loan.business.borrower.name,
          dueDate: dayjs(loan.loanEndTimeUtc).format('DD/MM/YYYY'),
          loanAmount: formatMoney(loan.amount, DEFAULT_CURRENCY),
          outStandingAmount: formatMoney(
            loan.outStandingBalance,
            DEFAULT_CURRENCY,
          ),
          phone: loan.business.borrower.phoneNumber,
        };
      });
    },
    [current, pending, arrears, paidUp],
  );

  const [activeTab, setActiveTab] = useState<string>(CURRENT);

  const [headers, setHeaders] = useState(currentHeaders);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  useActiveTab({setTab: setActiveTab, defaultTab: CURRENT, tab: activeTab});

  useEffect(() => {
    if (items.length > 0) return;
    switch (activeTab) {
      case CURRENT:
        const currentLoans = formatLoans(current);
        if (currentLoans.length > 0) setItems(currentLoans);
        break;
      case PAID_UP:
        const paidUpLoans = formatLoans(paidUp);
        if (paidUpLoans.length > 0) setItems(paidUpLoans);
        break;
      case PENDING:
        const pendingLoans = formatLoans(pending);
        if (pendingLoans.length > 0) setItems(pendingLoans);
        break;
      case ARREARS:
        const arrearsLoans = formatLoans(arrears);
        if (arrearsLoans.length > 0) setItems(arrearsLoans);
        break;
      default:
    }
  }, [current, pending, arrears, paidUp]);

  return (
    <HomePageLayout
      loading={
        pendingLoading || currentLoansLoading || arrearsLoading || paidUpLoading
      }>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Portfolio Manager" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <div className="w-6/12">
            <Tabs
              tabs={[
                {title: PENDING, active: activeTab === PENDING},
                {title: CURRENT, active: activeTab === CURRENT},
                {title: PAID_UP, active: activeTab === PAID_UP},
                {title: ARREARS, active: activeTab === ARREARS},
              ]}
              onClick={title => {
                let headers = [];
                let items: any[] = [];
                switch (title) {
                  case PENDING:
                    headers = pendingHeaders;
                    items = formatLoans(paidUp);
                  case PAID_UP:
                    headers = paidUpHeaders;
                    items = formatLoans(paidUp);
                    break;
                  case ARREARS:
                    headers = arrearsHeaders;
                    items = formatLoans(paidUp);
                    break;
                  case CURRENT:
                  default:
                    items = formatLoans(paidUp);
                    headers = paidUpHeaders;
                    break;
                }
                setHeaders(headers);
                setItems(items);
                setActiveTab(title);
              }}
            />
          </div>

          <ItemsTable headers={headers} items={items} />
        </div>
      </div>
    </HomePageLayout>
  );
};
