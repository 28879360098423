import React from 'react';

type Props = {
  open?: boolean;
  title?: string;
  message?: string;
  onClose: () => void;
  btnText?: string;
  id: string;
  onClick: (id: string) => void;
};

function DeletePrompt({
  title,
  message,
  open,
  onClose,
  btnText,
  id,
  onClick,
}: Props) {
  return (
    <>
      {open && (
        <div
          className="fixed flex justify-center w-full h-screen bg-gray-50/50 top-0 left-0 z-30 overflow-hidden p-4"
          onClick={onClose}>
          <div role="alert" className="min-w-[50%] mt-[20%]">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
              {title}
            </div>
            <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
              <p>{message}</p>
              <div className="flex pt-5 w-full justify-end">
                <button className="mx-4 px-6 py-2">cancel</button>
                <button
                  className="border-2 border-red-700 rounded-sm px-8 py-2"
                  onClick={() => onClick(id)}>
                  {btnText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

DeletePrompt.defaultProps = {
  open: false,
  title: 'An Error Occurred',
  button: 'Close',
};

export default DeletePrompt;
