import {gql} from '@apollo/client';

export const ROLES_QUERY = gql`
  query GetRoles {
    roles {
      edges {
        node {
          id
          name
          createdAtUtc
          permissions
        }
      }
    }
  }
`;
