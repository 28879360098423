import {Maybe} from '../gql/graphql';
import _ from 'lodash';
import {permissions as p} from '../constants/constants';
import {useCurrentUser} from './useCurrentUser';

export const useHasPermission = (): ((
  page: string,
  permissions?: Maybe<Maybe<string>[]>,
) => boolean) => {
  const {user} = useCurrentUser();
  return (page: string) => {
    if (!user?.permissions) return false;
    switch (page) {
      case '/app/dashboard':
        return (
          _.difference([p.PERMISSION_VIEW_DASHBOARD.name], user?.permissions)
            .length === 0
        );
      case '/app/pre-screening':
      case '/app/resubmitted-loans':
        return (
          _.difference(
            [
              p.PERMISSION_VIEW_LOAN_APPLICATIONS.name,
              p.PERMISSION_LENDER_CAN_UPDATE_PHOTO.name,
              p.PERMISSION_CREATE_LOAN_APPLICATION_COMMENT.name,
              p.PERMISSION_CREATE_LOAN_APPLICATION_CONTACT_ATTEMPT.name,
              p.PERMISSION_UPDATE_ASK_PHOTO_RESUBMISSION.name,
              p.PERMISSION_VIEW_BUSINESSES.name,
            ],
            user?.permissions,
          ).length === 0
        );
      case '/app/due-diligence':
      case '/app/dismissed-loans':
        return (
          _.difference(
            [
              p.PERMISSION_VIEW_LOAN_APPLICATIONS.name,
              p.PERMISSION_LENDER_CAN_UPDATE_PHOTO.name,
              p.PERMISSION_CREATE_LOAN_APPLICATION_COMMENT.name,
              p.PERMISSION_CREATE_LOAN_APPLICATION_CONTACT_ATTEMPT.name,
              p.PERMISSION_UPDATE_LOAN_QUOTE.name,
              p.PERMISSION_UPDATE_LOAN_APPLICATION_CATEGORY.name,
              p.PERMISSION_CREATE_VISIT_ATTEMPT.name,
              p.PERMISSION_UPDATE_ASK_PHOTO_RESUBMISSION.name,
              p.PERMISSION_VIEW_BUSINESSES.name,
            ],
            user?.permissions,
          ).length === 0
        );
      case '/app/final-review':
      case '/app/disburse':
        return (
          _.difference(
            [
              p.PERMISSION_VIEW_LOAN_APPLICATIONS.name,
              p.PERMISSION_LENDER_CAN_UPDATE_PHOTO.name,
              p.PERMISSION_CREATE_LOAN_APPLICATION_COMMENT.name,
              p.PERMISSION_CREATE_LOAN_APPLICATION_CONTACT_ATTEMPT.name,
              p.PERMISSION_UPDATE_LOAN_QUOTE.name,
              p.PERMISSION_UPDATE_LOAN_APPLICATION_CATEGORY.name,
              p.PERMISSION_CREATE_VISIT_ATTEMPT.name,
              p.PERMISSION_DISBURSE_LOANS.name,
              p.PERMISSION_UPDATE_ASK_PHOTO_RESUBMISSION.name,
              p.PERMISSION_VIEW_BUSINESSES.name,
            ],
            user?.permissions,
          ).length === 0
        );
      case '/app/settings':
        return (
          _.difference(
            [
              p.PERMISSION_VIEW_ROLES.name,
              p.PERMISSION_CREATE_LENDER.name,
              p.PERMISSION_CREATE_FORM.name,
              p.PERMISSION_CREATE_ROLE.name,
              p.PERMISSION_CREATE_PORTFOLIO.name,
              p.PERMISSION_CREATE_FORM.name,
            ],
            user?.permissions,
          ).length === 0
        );
      default:
        return false;
    }
  };
};
