import React from 'react';

type Props = {
  path?: string;
  applicationId: string;
};

type DetailTitleProps = {
  userName: string;
  district: string;
  businessName: string;
  businessId: string;
};

export const DetailTitleBar = ({
  userName,
  district,
  businessName,
  businessId,
}: DetailTitleProps) => {
  return (
    <div className="grid grid-cols-2 gap-1 w-full">
      <div className="flex flex-col">
        <div className="text-black text-lg">{userName}</div>
        <div className="text-gray-600 text-base">{district}</div>
      </div>
      <div className="flex flex-col">
        <div className="text-black text-lg">{businessName}</div>
        <div className="text-gray-600 text-base">{businessId}</div>
      </div>
    </div>
  );
};
