import React, {SyntheticEvent, useEffect, useState} from 'react';
import {navigate} from '@reach/router';
import {AppLogo} from '../components/AppLogo';
import {LoginLayout} from '../components/LoginLayout';
import {useEmailPasswordLogin} from '../hooks/useEmailPasswordLogin';
import {UMF_ADMIN_TOKEN} from '../constants/constants';
import {showToast} from '../components/Toast';

type Props = {
  path?: string;
};
export default function Login({}: Props) {
  const [username, setUserName] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [message, setMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const {
    data,
    error,
    loading: loadingMutation,
    emailPasswordLogin,
  } = useEmailPasswordLogin();

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    if (password.length == 0) {
      setPasswordError('Please enter your password');
      return;
    }
    // if pattern does not match phone number or email, show error
    if (
      !/^(?:\d{1,3}]|\+256|256|0|)([237]\d{8})$/g.test(username) &&
      !/\S+@\S+\.\S+/g.test(username)
    ) {
      setUserNameError('Please Enter a valid email or phone number');
      return;
    }
    console.log('logging in with username and password');
    try {
      await emailPasswordLogin({variables: {username, password}});
    } catch (e: any) {
      console.error('an error occurred when logging', e);
      setMessage(`An error occurred: ${e.message}`);
      showToast({
        open: true,
        message: `An Error occurred when trying to login: ${e.message}`,
        positive: false,
      });
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data?.success) {
      (async () =>
        await localStorage.setItem(UMF_ADMIN_TOKEN, data?.token ?? ''))();
      navigate('/app');
    } else if (data?.message) {
      setMessage(data?.message);
    }
  }, [data]);

  return (
    <LoginLayout loading={loadingMutation || loading}>
      <div className="flex flex-col h-screen mt-auto w-6/12 h-full items-center justify-center bg-white">
        <div className="grow"></div>
        <form
          className="p-8 h-full"
          onSubmit={async e => {
            await submit(e);
          }}>
          <div className="py-16">
            <AppLogo textClassName="text-blue-700" />
          </div>

          {message && <div className="text-orange-600 py-2">{message}</div>}

          <div className="text-base font-medium pb-8">
            Manage all loan applications and payments <br /> in one place
          </div>
          <div className="py-2 text-base ">Phone / Email</div>
          <input
            className="border border-gray-200 px-4 py-2 rounded-sm w-full mt-1"
            type="email"
            value={username}
            onChange={e => {
              if (userNameError) setUserNameError('');
              setUserName(e.target.value);
            }}
            placeholder="email@umf.com"
          />
          {userNameError && (
            <div className="text-base text-red-400 font-medium mt-1">
              {userNameError}
            </div>
          )}
          <div className="mt-4 py-2 text-base ">Password</div>
          <input
            type="password"
            placeholder="*****"
            // value={password}
            onChange={e => {
              if (passwordError) setPasswordError('');
              setPassword(e.target.value);
            }}
            className="border border-gray-200 px-4 py-2 rounded-sm w-full mt-1"
          />
          {passwordError && (
            <div className="text-base text-red-400 font-medium mt-1">
              {passwordError}
            </div>
          )}
          <div className="mt-4">
            Forgot password?{' '}
            <span className="text-blue-600 hover:underline cursor-pointer">
              Request for access
            </span>
          </div>
          <div className="flex w-48 justify-center bg-blue-900 px-4 py-2 mt-8 rounded-3xl shadow-sm cursor-pointer">
            <button className="text-gray-200" type="submit">
              Log in
            </button>
          </div>
        </form>
        <div className="grow"></div>
      </div>
    </LoginLayout>
  );
}
