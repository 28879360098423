import {navigate} from 'gatsby';
import React, {useEffect} from 'react';
import AccessDenied from '../../components/AccessDenied';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {
  DEFAULT_CURRENCY,
  LOAN_APPLICATIONS_STATUSES,
} from '../../constants/constants';
import {useHasPermission} from '../../hooks/useHasPermission';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';

type Props = {
  path?: string;
};

const headers = [
  {name: 'Officer Name'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Date Entered Final Review'},
  {name: 'Amount requested'},
  {name: 'Adjusted amount'},
  {name: 'Quoted Principal'},
];

export const FinalReview = ({}: Props) => {
  const {loanApplications, error, loading} = useLoanApplications([
    LOAN_APPLICATIONS_STATUSES.FINAL_REVIEW,
  ]);

  useEffect(() => {
    if (error) {
      invalidInput(
        `An error occurred when loading final review items, ${error.message}`,
      );
    }
  }, [error]);

  const getItems = () => {
    if (!loanApplications || !Array.isArray(loanApplications)) return [];
    return loanApplications.map(application => ({
      officer: `${application?.stage?.staff?.firstName} ${application?.stage?.staff?.lastName}`,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${application.id}/detail`);
          }}>
          {application.business.name}
        </div>
      ),
      clientName: application.business?.borrower?.nationalIdName,
      date: new Date(application?.stage?.createdAtUtc).toLocaleDateString(),
      amount: formatMoney(application?.loanQuote?.amount, DEFAULT_CURRENCY),
      proposedAmount:
        formatMoney(application?.loanQuote?.proposedAmount, DEFAULT_CURRENCY) ??
        '-',
      principal: formatMoney(
        application?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
    }));
  };

  const hasPerm = useHasPermission();
  if (!hasPerm('/app/final-review')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loading}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Final Review" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <ItemsTable headers={headers} items={getItems()} />
        </div>
      </div>
    </HomePageLayout>
  );
};
