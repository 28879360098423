import {navigate} from 'gatsby';
import React, {ReactNode, useEffect, useState} from 'react';
import AccessDenied from '../../components/AccessDenied';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput, showToast} from '../../components/Toast';
import {
  DEFAULT_CURRENCY,
  LOAN_APPLICATIONS_STATUSES,
} from '../../constants/constants';
import {useActiveTab} from '../../hooks/useActiveTab';
import {useHasPermission} from '../../hooks/useHasPermission';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';
import {ActionBtn} from '../resubmitted/components/ResubmitActionBtn';

type Props = {
  path?: string;
};

const dismissalHeaders = [
  {name: 'Loan Officer'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Amount'},
  {name: 'Loan limit'},
  {name: 'Dismissal Date'},
  {name: 'Phone no.'},
  {name: 'Action'},
];

const declinedHeaders = [
  {name: 'Loan Officer'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Amount'},
  {name: 'Loan limit'},
  {name: 'Dismissal Date'},
  {name: 'Phone no.'},
];

const SUBMITTED_FOR_DISMISSAL = 'Submitted For Dismissal';
const DECLINED = 'Declined';

type TabsType = 'Declined' | 'Submitted For Dismissal';

export const Dismissed = ({}: Props) => {
  const [activeTab, setActiveTab] = useState<string>(SUBMITTED_FOR_DISMISSAL);

  const [headers, setHeaders] = useState(dismissalHeaders);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  const {
    loanApplications: dismissed,
    error: loanApplicationsError,
    loading: loadingLoanApplications,
  } = useLoanApplications([LOAN_APPLICATIONS_STATUSES.SUBMITTED_FOR_DISMISSAL]);

  const {
    loanApplications: declined,
    error: declinedLoansError,
    loading: declinedLoansLoading,
  } = useLoanApplications([LOAN_APPLICATIONS_STATUSES.DECLINED]);

  useActiveTab(
    {
      setTab: setActiveTab,
      defaultTab: SUBMITTED_FOR_DISMISSAL,
      tab: activeTab,
    },
    title => {
      setHeaders(
        title === SUBMITTED_FOR_DISMISSAL ? dismissalHeaders : declinedHeaders,
      );
      setItems(
        title === SUBMITTED_FOR_DISMISSAL ? getDismissed() : getDeclined(),
      );
      setActiveTab(title);
    },
  );

  const getDismissed = (): any => {
    if (!dismissed || !Array.isArray(dismissed)) return [];
    return dismissed?.map(loanApplication => ({
      officer: `${loanApplication?.stage?.staff?.firstName} ${loanApplication?.stage?.staff?.lastName}`,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${loanApplication.id}/detail`);
          }}>
          {loanApplication.business.name}
        </div>
      ),
      clientName: loanApplication?.business?.borrower?.name,
      amount: formatMoney(
        loanApplication?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      limit: formatMoney(loanApplication?.loanQuote?.amount, DEFAULT_CURRENCY),
      date: new Date(loanApplication?.stage.createdAtUtc).toLocaleDateString(),
      phone: loanApplication?.business?.borrower?.phoneNumber,
      action: <ActionBtn id={loanApplication.id} />,
    }));
  };

  const getDeclined = (): any => {
    if (!declined || !Array.isArray(declined)) return [];
    return declined?.map(loanApplication => ({
      officer: `${loanApplication?.stage?.staff?.firstName} ${loanApplication?.stage?.staff?.lastName}`,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${loanApplication.id}/detail`);
          }}>
          {loanApplication.business.name}
        </div>
      ),
      clientName: loanApplication?.business?.borrower?.name,
      amount: formatMoney(
        loanApplication?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      limit: formatMoney(loanApplication?.loanQuote?.amount, DEFAULT_CURRENCY),
      date: new Date(loanApplication?.stage.createdAtUtc).toLocaleDateString(),
      phone: loanApplication?.business?.borrower?.phoneNumber,
    }));
  };

  useEffect(() => {
    if (items.length > 0) return;
    if (activeTab === SUBMITTED_FOR_DISMISSAL) {
      const dismissedLoans = getDismissed();
      if (dismissedLoans.length > 0) setItems(dismissedLoans);
      return;
    }
    if (activeTab === DECLINED) {
      const declinedLoans = getDeclined();
      if (declinedLoans.length > 0) setItems(declinedLoans);
    }
  }, [declined, dismissed]);

  useEffect(() => {
    if (loanApplicationsError) {
      console.error('loan applications', loanApplicationsError);
      invalidInput('Error occurred when loading loan applications');
    }
    if (declinedLoansError) {
      console.error('loan applications', declinedLoansError);
      invalidInput('Error occurred when loading declined loans');
    }
  }, [loanApplicationsError, declinedLoansError]);

  const hasPerm = useHasPermission();

  if (!hasPerm('/app/dismissed-loans')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loadingLoanApplications || declinedLoansLoading}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Dismissed" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <div className="w-5/12">
            <Tabs
              tabs={[
                {
                  title: SUBMITTED_FOR_DISMISSAL,
                  active: activeTab === SUBMITTED_FOR_DISMISSAL,
                },
                {title: DECLINED, active: activeTab === DECLINED},
              ]}
              onClick={title => {
                setHeaders(
                  title === SUBMITTED_FOR_DISMISSAL
                    ? dismissalHeaders
                    : declinedHeaders,
                );
                setItems(
                  title === SUBMITTED_FOR_DISMISSAL
                    ? getDismissed()
                    : getDeclined(),
                );
                setActiveTab(title);
              }}
            />
          </div>
          <ItemsTable items={items} headers={headers} />
        </div>
      </div>
    </HomePageLayout>
  );
};
