import {gql} from '@apollo/client';

export const RESUBMIT_PHOTO_MUTATION = gql`
  mutation ResubmitPhotoMutation(
    $loanApplicationId: ID!
    $photoTypes: [BusinessPhotoTypeOptionsType]!
  ) {
    resubmitPhoto(
      loanApplicationId: $loanApplicationId
      photoTypes: $photoTypes
    ) {
      resubmission {
        photoType
        id
      }
    }
  }
`;
