import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {UPDATE_LOAN_APPLICATION_CATEGORY} from '../mutations/updateLoanApplicationCategory';

export type LoanApplicationCategoryType = {
  id?: string;
  createdAtUtc?: string;
  loanApplicationId?: string;
  purposeOfLoanCategory?: string;
  purposeOfLoan?: string;
  honesty?: number;
  availability?: number;
  attitude?: number;
};
type UseLoanApplicationCategoryUpdate = {
  loading: boolean;
  error?: ApolloError;
  category: LoanApplicationCategoryType;
  updateLoanApplicationCategory: (options: MutationFunctionOptions) => void;
};

export const useUpdateLoanApplicationCategory =
  (): UseLoanApplicationCategoryUpdate => {
    const [updateLoanApplicationCategory, {loading, error, data}] = useMutation(
      UPDATE_LOAN_APPLICATION_CATEGORY,
    );
    return {
      updateLoanApplicationCategory,
      loading,
      error,
      category: data?.updateLoanApplicationCategory?.category,
    };
  };
