import {navigate} from 'gatsby';
import React, {useEffect} from 'react';
import AccessDenied from '../../components/AccessDenied';
import {Checkbox} from '../../components/checkbox';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {
  DEFAULT_CURRENCY,
  LOAN_APPLICATIONS_STATUSES,
} from '../../constants/constants';
import {useHasPermission} from '../../hooks/useHasPermission';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';

type Props = {
  path?: string;
};

const headers = [
  {name: 'Business name'},
  {name: 'Client name'},
  {name: 'Phone Number'},
  {name: 'Principal'},
  {name: 'Interest %'},
  {name: 'Term Days'},
  {name: 'Interest Amount'},
  {name: 'Accepted Terms'},
];

export const Disburse = ({}: Props) => {
  const {loanApplications, error, loading} = useLoanApplications([
    LOAN_APPLICATIONS_STATUSES.DISBURSED,
  ]);

  useEffect(() => {
    if (error) {
      invalidInput(
        `An error occurred when loading disbursed loans, ${error.message}`,
      );
    }
  }, [error]);

  const getItems = () => {
    if (!loanApplications || !Array.isArray(loanApplications)) return [];
    return loanApplications.map(application => ({
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${application.id}/detail`);
          }}>
          {application.business.name}
        </div>
      ),
      clientName: application.business?.borrower?.nationalIdName,
      phone: application?.business?.borrower?.phoneNumber,
      principal: formatMoney(
        application?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      interestPercentage: `${application.loanQuote.interest}%`,
      term: application?.loanQuote?.termDays,
      interestAmount:
        formatMoney(application?.loanQuote?.interestAmount, DEFAULT_CURRENCY) ??
        '-',
      acceptedTerms: (
        <Checkbox
          title={application?.acceptedTerms ? 'Yes' : 'No'}
          checked={application?.acceptedTerms}
          setChecked={() => {}}
        />
      ),
    }));
  };

  const hasPerm = useHasPermission();

  if (!hasPerm('/app/disburse')) return <AccessDenied />;

  return (
    <HomePageLayout>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Disburse" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <ItemsTable items={getItems()} headers={headers} />
        </div>
      </div>
    </HomePageLayout>
  );
};
